import {
  BEST_STREAK_TEXT,
  CURRENT_STREAK_TEXT,
  TOTAL_TRIES_TEXT,
} from '../../constants/strings'
import { GameStats } from '../../lib/localStorage'
import { StatBarItem } from './StatBarItem'

type Props = {
  gameStats: GameStats
}

export const OverallStatBar = ({ gameStats }: Props) => {
  return (
    <div className="my-2 flex justify-center">
      <StatBarItem label={TOTAL_TRIES_TEXT} value={gameStats.totalGames} />
      <StatBarItem label={CURRENT_STREAK_TEXT} value={gameStats.currentStreak} />
      <StatBarItem label={BEST_STREAK_TEXT} value={gameStats.bestStreak} />
    </div>
  )
}
