import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Kaip žaisti" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Įvedinėk žodžius, kol pasieksi paskutinį žodį. Kiekvienas žodis turi skirtis viena raide nuo prieš tai buvusio.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="L" status={"disabled"} />
        <Cell value="A" status={"disabled"} />
        <Cell value="B" status={"disabled"} />
        <Cell value="A" status={"disabled"} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
          <strong>LABA</strong> yra pradinis žodis, o <strong>KOVA</strong> - paskutinis.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="L" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell value="V" isCompleted={true} status={"correct"} />
        <Cell value="A" isCompleted={true} status={"correct"} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Pakeitus raidę <strong>B</strong> į <strong>V</strong>, gaunamas žodis <strong>LAVA</strong>.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="K" isCompleted={true} status={"correct"} />
        <Cell value="A" isCompleted={true} />
        <Cell value="V" isCompleted={true} status={"correct"} />
        <Cell value="A" isCompleted={true} status={"correct"} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Pakeitus raidę <strong>L</strong> į <strong>K</strong>, gaunamas žodis <strong>KAVA</strong>.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="K" isCompleted={true} status={"correct-target"} />
        <Cell value="O" isCompleted={true} status={"correct-target"} />
        <Cell value="V" isCompleted={true} status={"correct-target"} />
        <Cell value="A" isCompleted={true} status={"correct-target"} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Pakeitus pirmąją raidę <strong>A</strong> į <strong>O</strong>, gaunamas žodis <strong>KOVA</strong>.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Pergalė! Žodis pasiektas!
      </p>

      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        Jei radote klaidų, praneškite el. paštu {' '}
        <a
          href="mailto:hello@dashlook.app"
          className="font-bold underline">hello@dashlook.app</a>
      </p>
    </BaseModal>
  )
}
