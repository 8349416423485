export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Puiku!', 'Pavarei!', 'Valio!']
export const GAME_COPIED_MESSAGE = 'Nukopijuota'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Žodis per trumpas'
export const WORD_NOT_FOUND_MESSAGE = 'Žodis nerastas'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Ryškesnėm spalvom'
export const CHANGED_TOO_MUCH_LETTERS = 'Negalima keisti daugiau nei vienos raidės'
export const DID_NOT_CHANGE_LETTERS = 'Reik pakeist bent vieną raidę'
export const ENTER_TEXT = 'ENTER'
export const DELETE_TEXT = 'Trinti'
export const STATISTICS_TITLE = 'Statistika'
export const NEW_WORD_TEXT = 'Naujas žodis už'
export const SHARE_TEXT = 'Dalinkis'
export const SHARE_FAILURE_TEXT =
  'Nepavyko pasidalinti rezultatais. Ši funkcija galima tik saugiose naršyklių aplinkose (HTTPS).'
export const MIGRATE_BUTTON_TEXT = 'Perkelti'
export const MIGRATE_DESCRIPTION_TEXT =
  'Spauskite čia statistikos perkėlimui į kitą įrenginį.'
export const TODAY_GUESS_AMOUNT_TEXT = 'Tavo spėjimai'
export const TODAY_OPTIMAL_GUESS_AMOUNT_TEXT = 'Optimalus spėjimų skaičius'
export const TOTAL_TRIES_TEXT = 'Iš viso bandymų'
export const CURRENT_STREAK_TEXT = 'Įveikta iš eilės'
export const BEST_STREAK_TEXT = 'Daugiausiai įveikta iš eilės'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "Jūs naudojate integruotą naršyklę (pvz. programėlėse), dėl ko gali kilti neskalandumų dalinantis ar išsaugant jūsų rezultatus. Kad išvengti netikėtumų, siūlome naudotis įprastąją įrenginio naršykle."

export const DATEPICKER_TITLE = 'Pasirink datą'
export const DATEPICKER_CHOOSE_TEXT = 'Rinktis'
export const DATEPICKER_TODAY_TEXT = 'šiandien'
export const ARCHIVE_GAMEDATE_TEXT = 'Žaidimo data'
