import { unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  word: string
}

export const StartRow = ({ word }: Props) => {
  const splitWord = unicodeSplit(word)

  return (
    <div className="mb-1 flex justify-center">
      {splitWord.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          status={"disabled"}
          position={i}
          isCompleted
        />
      ))}
    </div>
  )
}
