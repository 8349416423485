import { UAParser } from 'ua-parser-js'

import {wordPairIndex, WordPair, countCorrectLetters} from './words'

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable']
const parser = new UAParser()
const browser = parser.getBrowser()
const device = parser.getDevice()

export const shareStatus = (
  wordPair: WordPair,
  guesses: string[],
  isDarkMode: boolean,
  isHighContrastMode: boolean,
  handleShareToClipboard: () => void,
  handleShareFailure: () => void
) => {
  const textToShare =
    (wordPair.optimalPathLength === guesses.length ? '🏆' : '') +
    `✅ ZIG #${wordPairIndex+1}\n\n\t${wordPair.start}\n` +
    generateEmojiGuesses(wordPair.target, guesses) +
    `\n\t${wordPair.target}\n\n` +
    `🚀 Optimalus: ${wordPair.optimalPathLength}`

  const shareData = { text: textToShare }

  let shareSuccess = false

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData)
      shareSuccess = true
    }
  } catch (error) {
    shareSuccess = false
  }

  try {
    if (!shareSuccess) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToShare)
          .then(handleShareToClipboard)
          .catch(handleShareFailure)
      } else {
        handleShareFailure()
      }
    }
  } catch (error) {
    handleShareFailure()
  }
}

export const generateEmojiGuesses = (
  targetWord: string,
  guesses: string[],
) => {
  return guesses
    .map((guess, row) => {
      const correctLettersCount = countCorrectLetters(guess, targetWord)
      let emojis = row+1 + '.\t'
      if (correctLettersCount === 0) {
        return emojis + '⬜'
      }
      for (let i = 0; i < correctLettersCount; i++) {
        emojis += '🟩'
      }
      return emojis
    })
    .join('\n')
}

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
}
