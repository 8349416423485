import {unicodeSplit, WordPair} from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  wordPair: WordPair
  guesses: string[]
}

export const TargetRow = ({ wordPair, guesses }: Props) => {
  const splitTarget = unicodeSplit(wordPair.target)
  const lastGuess = guesses[guesses.length - 1] ?? wordPair.start
  const splitLastGuess = unicodeSplit(lastGuess)

  return (
    <div className="mb-1 flex justify-center">
      {splitTarget.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          status={splitLastGuess[i] === splitTarget[i] ? 'correct-target' : 'disabled'}
          position={i}
          isCompleted
          bounce={lastGuess === wordPair.target}
        />
      ))}
    </div>
  )
}
