import classnames from 'classnames'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'

type Props = {
  value?: string
  status?: CharStatus
  isCompleted?: boolean
  position?: number
  bounce?: boolean
}

export type CharStatus = 'disabled' | 'correct' | 'correct-target'

export const Cell = ({
  value,
  status,
  isCompleted,
  position = 0,
  bounce = false,
}: Props) => {
  const isFilled = value && !isCompleted
  const isHighContrast = getStoredIsHighContrastMode()
  const bounceDelay = `${position * 50}ms`

  const classes = classnames(
    'xxshort:w-11 xxshort:h-11 short:text-2xl short:w-12 short:h-12 w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white',
    {
      'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600':
        !status,
      'border-black dark:border-slate-100':
        value && !status,
      'absent shadowed bg-slate-400 dark:bg-slate-700 text-white border-slate-400 dark:border-slate-700':
        status === 'disabled',
      'correct shadowed bg-orange-500 text-white border-orange-500':
        status === 'correct' && isHighContrast,
      'correct shadowed bg-green-500 text-white border-green-500':
        status === 'correct' && !isHighContrast,
      'correct-target shadowed bg-green-700 text-white border-green-700':
        status === 'correct-target' && !isHighContrast,
      'cell-fill-animation': isFilled,
      'bouncing-cell': bounce
    }
  )

  return (
    <div className={classes} style={{"animationDelay": bounceDelay}}>
      <div className="letter-container">
        {value}
      </div>
    </div>
  )
}
