import {
    TODAY_GUESS_AMOUNT_TEXT,
    TODAY_OPTIMAL_GUESS_AMOUNT_TEXT,
} from '../../constants/strings'
import { StatBarItem } from './StatBarItem'
import {WordPair} from "../../lib/words";

type Props = {
  wordPair: WordPair
  guesses: string[]
}

export const TodayStatBar = ({ wordPair, guesses }: Props) => {
    return (
        <div className="my-2 flex justify-center">
            <StatBarItem label={TODAY_GUESS_AMOUNT_TEXT} value={guesses.length} />
            <StatBarItem label={TODAY_OPTIMAL_GUESS_AMOUNT_TEXT} value={wordPair.optimalPathLength} />
        </div>
    )
}
