import { useEffect } from 'react'

import { ENTER_TEXT } from '../../constants/strings'
import {localeAwareUpperCase} from '../../lib/words'
import { Key } from './Key'
import {BackspaceIcon} from "@heroicons/react/outline";

type Props = {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
}

export const Keyboard = ({
  onChar,
  onDelete,
  onEnter,
}: Props) => {
  const onClick = (value: string) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onChar(value)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onEnter()
      } else if (e.key === 'Backspace') {
        onDelete()
      } else {
        const key = localeAwareUpperCase(e.key)
        if (key.length === 1 && key >= 'A' && key <= 'Z' && key !== 'Q' && key !== 'W' && key !== 'X') {
          onChar(key)
        } else if (key === 'Ą' || key === 'Č' || key === 'Ę' || key === 'Ė' ||
            key === 'Į' || key === 'Š' || key === 'Ų' || key === 'Ū' ||
            key === 'Ž') {
          onChar(key)
        }
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar])

  return (
    <div>
      <div className="mb-1 flex justify-center">
        {['Ą', 'Č', 'Ę', 'Ė', 'Į', 'Š', 'Ų', 'Ū', 'Ž'].map((key) => (
            <Key
                value={key}
                key={key}
                onClick={onClick}
            />
        ))}
      </div>
      <div className="mb-1 flex justify-center">
        {['E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
          />
        ))}
      </div>
      <div className="mb-1 flex justify-center">
        {['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
          />
        ))}
      </div>
      <div className="flex justify-center">
        <Key width={65.4} value={ENTER_TEXT} highlighted={true} onClick={onClick}>
          {ENTER_TEXT}
        </Key>
        {['Z', 'C', 'V', 'B', 'N', 'M'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
          />
        ))}
        <Key width={65.4} value="DELETE" onClick={onClick}>
          <BackspaceIcon className="h-6 w-6 cursor-pointer dark:stroke-white"/>
        </Key>
      </div>
    </div>
  )
}
