import {addDays, differenceInDays, formatISO, parseISO, startOfDay,} from 'date-fns'
import {default as GraphemeSplitter} from 'grapheme-splitter'
import queryString from 'query-string'

import {ENABLE_ARCHIVED_GAMES} from '../constants/settings'
import {VALID_GUESSES} from '../constants/validGuesses'
import {WORD_PAIRS} from '../constants/wordPairs'
import {getToday} from './dateutils'

export type WordPair = {
  start: string
  target: string
  optimalPathLength: number
}

// 1 January 2022 Game Epoch
export const firstGameDate = addDays(new Date(2024, 2), 3)
export const periodInDays = 1

export const isWordInWordList = (word: string) => {
  return VALID_GUESSES.includes(localeAwareLowerCase(word))
}

export const countChangedLetters = (currentGuess: string, guesses: string[]) => {
  const lastWord = guesses.length === 0
      ? wordPair.start
      : guesses[guesses.length - 1]
  const lastWordLetters = unicodeSplit(lastWord)
  const currentGuessLetters = unicodeSplit(currentGuess)
  let changedCount = 0
  for (let i = 0; i < lastWordLetters.length; i++) {
    if (lastWordLetters[i] !== currentGuessLetters[i]) {
      changedCount++
    }
  }
  return changedCount
}

export const countCorrectLetters = (word: string, targetWord: string) => {
  const wordLetters = unicodeSplit(word)
  const targetWordLetters = unicodeSplit(targetWord)
  let count = 0
  for (let i = 0; i < targetWordLetters.length; i++) {
    if (wordLetters[i] === targetWordLetters[i]) {
      count++
    }
  }
  return count
}

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

export const getLastGameDate = (today: Date) => {
  const t = startOfDay(today)
  let daysSinceLastGame = differenceInDays(firstGameDate, t) % periodInDays
  return addDays(t, -daysSinceLastGame)
}

export const getNextGameDate = (today: Date) => {
  return addDays(getLastGameDate(today), periodInDays)
}

export const isValidGameDate = (date: Date) => {
  if (date < firstGameDate || date > getToday()) {
    return false
  }

  return differenceInDays(firstGameDate, date) % periodInDays === 0
}

export const getIndex = (gameDate: Date) => {
  let start = firstGameDate
  let index = -1
  do {
    index++
    start = addDays(start, periodInDays)
  } while (start <= gameDate)
  return index
}

export const getWordPairOfDay = (index: number) => {
  if (index < 0) {
    throw new Error('Invalid index')
  }
  const pair = WORD_PAIRS[index % WORD_PAIRS.length]
  return {
    start: localeAwareUpperCase(pair[0] as string),
    target: localeAwareUpperCase(pair[1] as string),
    optimalPathLength: pair[2],
  } as WordPair
}

export const getWordPair = (gameDate: Date) => {
  const index = getIndex(gameDate)
  return {
    wordPair: getWordPairOfDay(index),
    wordPairGameDate: gameDate,
    wordPairIndex: index,
    tomorrow: getNextGameDate(gameDate).valueOf(),
  }
}

export const getGameDate = () => {
  if (getIsLatestGame()) {
    return getToday()
  }

  const parsed = queryString.parse(window.location.search)
  try {
    const d = startOfDay(parseISO(parsed.d!.toString()))
    if (d >= getToday() || d < firstGameDate) {
      setGameDate(getToday())
    }
    return d
  } catch (e) {
    console.log(e)
    return getToday()
  }
}

export const setGameDate = (d: Date) => {
  try {
    if (d < getToday()) {
      window.location.href = '/?d=' + formatISO(d, { representation: 'date' })
      return
    }
  } catch (e) {
    console.log(e)
  }
  window.location.href = '/'
}

export const getIsLatestGame = () => {
  if (!ENABLE_ARCHIVED_GAMES) {
    return true
  }
  const parsed = queryString.parse(window.location.search)
  return parsed === null || !('d' in parsed)
}

export const { wordPair, wordPairGameDate, wordPairIndex, tomorrow } =
  getWordPair(getGameDate())
