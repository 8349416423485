import React, { ReactNode } from 'react'
import classnames from "classnames";

type Props = {
  children?: ReactNode
  value: string
  width?: number
  highlighted?: boolean
  onClick: (value: string) => void
}

export const Key = ({
  children,
  width = 40,
  value,
  highlighted,
  onClick,
}: Props) => {

  const styles = {
    width: `${width}px`,
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }

  const classes = classnames(
    "xxshort:h-8 xxshort:w-8 xxshort:text-xxs xshort:w-10 xshort:h-10 flex short:h-12 h-14 items-center justify-center rounded mx-0.5 text-sm font-medium cursor-pointer select-none dark:text-white bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 dark:hover:bg-slate-500 active:bg-slate-400",
    {
      "bg-green-400 dark:bg-green-500 hover:bg-green-300 dark:hover:bg-green-400 active:bg-green-500 dark:active:bg-green-600": highlighted
    }
  )

  return (
    <button
      style={styles}
      aria-label={`${value}`}
      className={classes}
      onClick={handleClick}
    >
      {children || value}
    </button>
  )
}
