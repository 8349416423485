import {
  GameStats,
  loadStatsFromLocalStorage,
  saveStatsToLocalStorage,
} from './localStorage'

export const addStatsForCompletedGame = (gameStats: GameStats,) => {
  const stats = { ...gameStats }

  stats.totalGames += 1
  stats.currentStreak += 1

  if (stats.bestStreak < stats.currentStreak) {
    stats.bestStreak = stats.currentStreak
  }

  saveStatsToLocalStorage(stats)
  return stats
}

const defaultStats: GameStats = {
  currentStreak: 0,
  bestStreak: 0,
  totalGames: 0,
}

export const loadStats = () => {
  return loadStatsFromLocalStorage() || defaultStats
}
