export const VALID_GUESSES = [
	'actų',
	'actą',
	'actu',
	'acto',
	'acte',
	'ačiū',
	'adėt',
	'adęs',
	'adėm',
	'adei',
	'adyk',
	'adyt',
	'adau',
	'adot',
	'adąs',
	'adom',
	'adai',
	'aibė',
	'aibę',
	'aibe',
	'aida',
	'aidą',
	'aidu',
	'aidi',
	'aidų',
	'aido',
	'aide',
	'aidį',
	'ainė',
	'ainę',
	'aine',
	'ainį',
	'aini',
	'aipo',
	'aižė',
	'aižę',
	'aižo',
	'aižą',
	'aižų',
	'aiži',
	'aižu',
	'akės',
	'akėk',
	'akėt',
	'akis',
	'akių',
	'akys',
	'akie',
	'akyk',
	'akyt',
	'aklų',
	'aklą',
	'aklu',
	'aklo',
	'akli',
	'akla',
	'aklė',
	'aklę',
	'akle',
	'aklį',
	'akly',
	'akau',
	'akot',
	'akęs',
	'akus',
	'akom',
	'akai',
	'aktų',
	'aktą',
	'aktu',
	'akto',
	'akte',
	'akti',
	'teak',
	'neak',
	'beak',
	'akit',
	'akim',
	'aksi',
	'akta',
	'alfa',
	'alfų',
	'alfą',
	'alga',
	'algų',
	'algą',
	'alio',
	'alka',
	'alkų',
	'alką',
	'alku',
	'alko',
	'alke',
	'alkį',
	'alki',
	'alkę',
	'alks',
	'alkt',
	'alma',
	'almą',
	'almu',
	'almi',
	'alpo',
	'alpę',
	'alps',
	'alpk',
	'alpt',
	'alpų',
	'alpi',
	'alpu',
	'alsų',
	'alsą',
	'alsu',
	'also',
	'alse',
	'alsi',
	'altų',
	'altą',
	'altu',
	'alto',
	'alte',
	'alus',
	'alau',
	'alūs',
	'aluj',
	'alui',
	'amen',
	'amsi',
	'amsį',
	'anga',
	'angų',
	'angą',
	'angį',
	'angy',
	'anka',
	'anką',
	'anku',
	'anki',
	'anot',
	'antį',
	'anti',
	'anty',
	'apak',
	'apės',
	'apie',
	'aras',
	'arus',
	'arui',
	'arai',
	'arba',
	'arda',
	'ardų',
	'ardą',
	'ardu',
	'ardo',
	'arde',
	'ardė',
	'ardę',
	'ardi',
	'arėt',
	'aręs',
	'arėm',
	'arei',
	'arfa',
	'arfų',
	'arfą',
	'argi',
	'aria',
	'arią',
	'ariu',
	'arka',
	'arkų',
	'arką',
	'arpų',
	'arpą',
	'arpu',
	'arpo',
	'arpe',
	'aršų',
	'arši',
	'aršu',
	'arti',
	'artų',
	'artą',
	'artu',
	'arto',
	'arsi',
	'arta',
	'artį',
	'ąsos',
	'ąsas',
	'ąsom',
	'ąsoj',
	'ąsai',
	'asas',
	'asus',
	'asui',
	'asai',
	'asių',
	'ases',
	'asės',
	'asėm',
	'asėj',
	'asei',
	'asla',
	'aslų',
	'aslą',
	'ąsok',
	'ąsot',
	'ašis',
	'ašių',
	'ašys',
	'ašie',
	'atak',
	'atūš',
	'audė',
	'audę',
	'audi',
	'auga',
	'augą',
	'augu',
	'augi',
	'augo',
	'augę',
	'augs',
	'augt',
	'augų',
	'auka',
	'aukų',
	'auką',
	'aulų',
	'aulą',
	'aulu',
	'aulo',
	'aule',
	'auna',
	'auną',
	'aunu',
	'auni',
	'aura',
	'aure',
	'ausų',
	'ausį',
	'ausy',
	'ausk',
	'ausi',
	'aust',
	'aušo',
	'aušę',
	'aušk',
	'auši',
	'aušt',
	'autų',
	'autą',
	'autu',
	'auto',
	'aute',
	'auti',
	'auta',
	'avėt',
	'avęs',
	'avus',
	'avėm',
	'avei',
	'avės',
	'avėk',
	'aviu',
	'avit',
	'avįs',
	'avim',
	'avis',
	'avių',
	'avys',
	'avie',
	'babt',
	'badų',
	'badą',
	'badu',
	'bado',
	'bade',
	'badė',
	'badę',
	'badi',
	'baik',
	'bajų',
	'bakų',
	'baką',
	'baku',
	'bako',
	'bake',
	'bala',
	'balų',
	'balą',
	'bąla',
	'bąlą',
	'bąlu',
	'bąli',
	'balu',
	'balo',
	'bale',
	'balę',
	'bals',
	'balk',
	'balt',
	'bara',
	'barą',
	'baru',
	'bari',
	'barų',
	'baro',
	'bare',
	'barė',
	'barę',
	'barį',
	'bars',
	'bark',
	'bart',
	'basų',
	'basą',
	'basu',
	'baso',
	'basi',
	'basa',
	'basė',
	'basę',
	'base',
	'bast',
	'batų',
	'batą',
	'batu',
	'bato',
	'bate',
	'baus',
	'bazė',
	'bazę',
	'baze',
	'bėda',
	'bėdų',
	'bėdą',
	'beda',
	'bedą',
	'bedu',
	'bedi',
	'bedė',
	'bedę',
	'bėga',
	'bėgų',
	'bėgą',
	'bėgu',
	'bėgi',
	'bėgį',
	'bėgo',
	'bėgę',
	'bėgs',
	'bėgt',
	'beje',
	'bels',
	'bene',
	'bent',
	'bėra',
	'bėrų',
	'bėrą',
	'bėru',
	'bėro',
	'bėri',
	'bėrė',
	'bėrę',
	'bėre',
	'beri',
	'bėrį',
	'bers',
	'berk',
	'bert',
	'besk',
	'besi',
	'best',
	'bėžį',
	'bėži',
	'bigė',
	'bigę',
	'bige',
	'bijo',
	'biją',
	'biro',
	'birę',
	'birs',
	'birk',
	'birt',
	'birų',
	'biri',
	'biru',
	'bisų',
	'bisą',
	'bisu',
	'biso',
	'bise',
	'bitų',
	'bitą',
	'bitu',
	'bito',
	'bite',
	'bitė',
	'bitę',
	'bygs',
	'byla',
	'bylų',
	'bylą',
	'byli',
	'bylu',
	'byra',
	'byrą',
	'byru',
	'byri',
	'byrį',
	'blės',
	'blęs',
	'blįs',
	'blok',
	'bluk',
	'boba',
	'bobų',
	'bobą',
	'bodo',
	'bodų',
	'bodi',
	'bodu',
	'boja',
	'boją',
	'boju',
	'boji',
	'bojo',
	'boję',
	'borų',
	'borą',
	'boru',
	'boro',
	'bore',
	'bosų',
	'bosą',
	'bosu',
	'boso',
	'bose',
	'botų',
	'botą',
	'botu',
	'boto',
	'bote',
	'boti',
	'bosi',
	'bota',
	'bręs',
	'brėš',
	'brįs',
	'bris',
	'bruk',
	'brūs',
	'buba',
	'bubą',
	'bubu',
	'bubi',
	'bubt',
	'būbt',
	'būda',
	'būdų',
	'būdą',
	'būdu',
	'būdo',
	'būde',
	'budė',
	'budę',
	'bude',
	'budi',
	'budį',
	'budy',
	'budo',
	'būgo',
	'būgę',
	'būgs',
	'būgt',
	'bukų',
	'buką',
	'buku',
	'buko',
	'buki',
	'buka',
	'buke',
	'bukė',
	'bukę',
	'bukį',
	'buks',
	'bukt',
	'bumų',
	'bumą',
	'bumu',
	'bumo',
	'bume',
	'būna',
	'būrų',
	'būrą',
	'būru',
	'būro',
	'būre',
	'burė',
	'burę',
	'bure',
	'būrė',
	'būrę',
	'buri',
	'būrį',
	'būry',
	'burk',
	'burs',
	'burt',
	'busk',
	'busi',
	'bust',
	'butų',
	'butą',
	'butu',
	'buto',
	'bute',
	'būti',
	'būtų',
	'būtą',
	'būtu',
	'būto',
	'būsi',
	'būta',
	'būtį',
	'būty',
	'būva',
	'būvi',
	'būvį',
	'buvo',
	'buza',
	'buzų',
	'buzą',
	'bužį',
	'bužy',
	'cakt',
	'capt',
	'carų',
	'carą',
	'caru',
	'caro',
	'care',
	'cėcė',
	'cėcę',
	'cėce',
	'celė',
	'celę',
	'cele',
	'cerį',
	'ceri',
	'cezį',
	'cezi',
	'ciba',
	'cibė',
	'cibę',
	'cibe',
	'cinų',
	'ciną',
	'cinu',
	'cino',
	'cine',
	'cipt',
	'cite',
	'cypė',
	'cypę',
	'cype',
	'cypi',
	'cypt',
	'cyps',
	'cypk',
	'cypų',
	'cypu',
	'colį',
	'coli',
	'cūga',
	'cūgų',
	'cūgą',
	'čekų',
	'čeką',
	'čeku',
	'čeko',
	'čeke',
	'čekė',
	'čekę',
	'čekį',
	'čeki',
	'čeža',
	'čežą',
	'čežu',
	'čeži',
	'čion',
	'čiuk',
	'čiūk',
	'čiur',
	'čižų',
	'čižą',
	'čižu',
	'čižo',
	'čiže',
	'čižę',
	'čižk',
	'čiši',
	'čižt',
	'čypė',
	'čypę',
	'čypi',
	'čyps',
	'čypk',
	'čypt',
	'čyrū',
	'čyžė',
	'čyžę',
	'čyžk',
	'čyši',
	'čyžt',
	'daga',
	'dagų',
	'dagą',
	'dagį',
	'dagy',
	'dagi',
	'dagu',
	'dalį',
	'daly',
	'dalų',
	'dali',
	'dalu',
	'dama',
	'damų',
	'damą',
	'danų',
	'daną',
	'danu',
	'dano',
	'dane',
	'danė',
	'danę',
	'darė',
	'darę',
	'dark',
	'daro',
	'darą',
	'darų',
	'dari',
	'daru',
	'dašį',
	'daši',
	'data',
	'datų',
	'datą',
	'daug',
	'dauš',
	'davė',
	'davę',
	'dažė',
	'dažę',
	'dažo',
	'dažą',
	'dėbt',
	'deda',
	'dedą',
	'dedu',
	'dedi',
	'dėdė',
	'dėdę',
	'dėde',
	'dega',
	'degą',
	'degu',
	'degi',
	'degė',
	'degę',
	'degs',
	'degt',
	'degų',
	'deja',
	'dejų',
	'deją',
	'dėjo',
	'dėję',
	'dėka',
	'dėkų',
	'dėką',
	'dėlė',
	'dėlę',
	'dėle',
	'dels',
	'dėmė',
	'dėmę',
	'dėme',
	'dėmi',
	'dėmį',
	'denk',
	'denį',
	'deni',
	'depų',
	'depą',
	'depu',
	'depo',
	'depe',
	'dera',
	'derą',
	'deru',
	'deri',
	'derk',
	'dėro',
	'dėrę',
	'dėrs',
	'dėrk',
	'dėrt',
	'dėtų',
	'dėtą',
	'dėtu',
	'dėto',
	'dėti',
	'dėta',
	'dėsi',
	'dėtį',
	'dėty',
	'dėvi',
	'dėvį',
	'dėžė',
	'dėžę',
	'dėže',
	'didų',
	'didi',
	'didu',
	'diek',
	'dilo',
	'dilę',
	'dils',
	'dilk',
	'dilt',
	'dilų',
	'dili',
	'dilu',
	'dina',
	'dinų',
	'diną',
	'dink',
	'dirk',
	'diri',
	'dirs',
	'dirt',
	'dirš',
	'dydį',
	'dydi',
	'dygė',
	'dygę',
	'dyge',
	'dygi',
	'dygį',
	'dygy',
	'dygo',
	'dygs',
	'dygt',
	'dygų',
	'dygu',
	'dykų',
	'dyką',
	'dyku',
	'dyko',
	'dyki',
	'dyka',
	'dykę',
	'dyks',
	'dykt',
	'dyla',
	'dylą',
	'dylu',
	'dyli',
	'dynė',
	'dynę',
	'dyne',
	'dynį',
	'dyny',
	'dyrė',
	'dyrę',
	'dyri',
	'dyrį',
	'dyro',
	'dyrą',
	'dyža',
	'dyžų',
	'dyžą',
	'dyžė',
	'dyžę',
	'dyžk',
	'dyši',
	'dyžt',
	'dobė',
	'dobę',
	'dobi',
	'dobs',
	'dobk',
	'dobt',
	'dogų',
	'dogą',
	'dogu',
	'dogo',
	'doge',
	'dokų',
	'doką',
	'doku',
	'doko',
	'doke',
	'domė',
	'domę',
	'dome',
	'domų',
	'domi',
	'domu',
	'dora',
	'dorų',
	'dorą',
	'doru',
	'doro',
	'dori',
	'dovį',
	'dovi',
	'dozė',
	'dozę',
	'doze',
	'drėk',
	'drik',
	'drįs',
	'dryk',
	'droš',
	'drūs',
	'duba',
	'dubų',
	'dubą',
	'dubė',
	'dubę',
	'dube',
	'dubo',
	'dubt',
	'dubs',
	'dubk',
	'dubi',
	'dubu',
	'dūda',
	'dūdų',
	'dūdą',
	'dūgs',
	'duis',
	'duik',
	'duit',
	'duja',
	'dujų',
	'dują',
	'duju',
	'duji',
	'dūja',
	'dūją',
	'dūju',
	'dūji',
	'dujo',
	'duję',
	'dūkį',
	'dūki',
	'dūko',
	'dūkę',
	'dūks',
	'dūkt',
	'dūkų',
	'dūku',
	'dūla',
	'dūlą',
	'dūlu',
	'dūli',
	'dūlų',
	'dūlo',
	'dūle',
	'dūlė',
	'dūlę',
	'dūlį',
	'dūly',
	'dulk',
	'dulo',
	'dulę',
	'duls',
	'dult',
	'dūmų',
	'dūmą',
	'dūmu',
	'dūmo',
	'dūme',
	'dūmė',
	'dūmę',
	'dumi',
	'dums',
	'dumk',
	'dumt',
	'duos',
	'duok',
	'duot',
	'dūrė',
	'dūrę',
	'duri',
	'dūrį',
	'dūri',
	'durų',
	'durs',
	'durk',
	'durt',
	'dusų',
	'dusą',
	'dusu',
	'duso',
	'duse',
	'dūsi',
	'dūsį',
	'dusę',
	'dusk',
	'dusi',
	'dust',
	'dušų',
	'dušą',
	'dušu',
	'dušo',
	'duše',
	'dūžį',
	'dūži',
	'dužo',
	'dužę',
	'dužk',
	'duši',
	'dužt',
	'dužų',
	'duži',
	'dužu',
	'dvak',
	'dvės',
	'dvis',
	'dvok',
	'dzin',
	'ėdat',
	'ėdąs',
	'ėdam',
	'ėdėt',
	'ėdęs',
	'ėdus',
	'ėdėm',
	'ėdei',
	'ėdis',
	'ėdra',
	'ėdrų',
	'ėdrą',
	'ėdro',
	'ėdri',
	'ėdru',
	'ėdūs',
	'eglė',
	'eglę',
	'egle',
	'eibė',
	'eibę',
	'eibe',
	'eiga',
	'eigų',
	'eigą',
	'eikš',
	'eilė',
	'eilę',
	'eile',
	'einį',
	'eini',
	'einų',
	'einu',
	'ėjos',
	'ėjas',
	'ėjom',
	'ėjoj',
	'ėjai',
	'ėjau',
	'ėjot',
	'ėjęs',
	'ėjus',
	'elnė',
	'elnę',
	'elne',
	'elny',
	'ėmėt',
	'ėmęs',
	'ėmus',
	'ėmėm',
	'ėmei',
	'engė',
	'engę',
	'engi',
	'engs',
	'engt',
	'epas',
	'epus',
	'epui',
	'epai',
	'eros',
	'eras',
	'erom',
	'eroj',
	'erai',
	'ėras',
	'ėrus',
	'ėrui',
	'ėrai',
	'erbį',
	'erbi',
	'ergų',
	'ergą',
	'ergu',
	'ergo',
	'erge',
	'erkė',
	'erkę',
	'erke',
	'ernį',
	'erni',
	'erza',
	'erzą',
	'erzu',
	'erzi',
	'esių',
	'eses',
	'esės',
	'esėm',
	'esėj',
	'esei',
	'esmė',
	'esmę',
	'esme',
	'estų',
	'estą',
	'estu',
	'esto',
	'este',
	'estė',
	'estę',
	'ėsti',
	'teės',
	'neės',
	'beės',
	'ėstų',
	'ėsią',
	'ėstą',
	'ėsiu',
	'ėstu',
	'ėsit',
	'ėsis',
	'ėsto',
	'ėsim',
	'ėsta',
	'ežia',
	'ežių',
	'ežią',
	'ežys',
	'ežiu',
	'ežio',
	'fazė',
	'fazę',
	'faze',
	'fėja',
	'fėjų',
	'fėją',
	'figa',
	'figų',
	'figą',
	'filė',
	'filę',
	'file',
	'finų',
	'finą',
	'finu',
	'fino',
	'fine',
	'finė',
	'finę',
	'fonų',
	'foną',
	'fonu',
	'fono',
	'fone',
	'fotų',
	'fotą',
	'fotu',
	'foto',
	'fote',
	'fuga',
	'fugų',
	'fugą',
	'gabų',
	'gabi',
	'gabu',
	'gaga',
	'gagų',
	'gagą',
	'gagu',
	'gagi',
	'gaiš',
	'gaja',
	'gajų',
	'gają',
	'gaji',
	'gaju',
	'galų',
	'galą',
	'galu',
	'galo',
	'gale',
	'galė',
	'galę',
	'gali',
	'galį',
	'gama',
	'gamų',
	'gamą',
	'gana',
	'ganė',
	'ganę',
	'gano',
	'ganą',
	'ganų',
	'gani',
	'ganu',
	'garų',
	'garą',
	'garu',
	'garo',
	'gare',
	'gark',
	'gari',
	'garį',
	'gaus',
	'gauk',
	'gaut',
	'gauš',
	'gavi',
	'gavo',
	'gavę',
	'geba',
	'gebų',
	'gebą',
	'gebu',
	'gebi',
	'gėda',
	'gėdų',
	'gėdą',
	'gedi',
	'gedį',
	'gedo',
	'gedę',
	'gegė',
	'gegę',
	'gege',
	'geis',
	'gėla',
	'gėlų',
	'gėlą',
	'gėlu',
	'gėlo',
	'gėli',
	'gėlė',
	'gėlę',
	'gėle',
	'geli',
	'gels',
	'gelk',
	'gelt',
	'gena',
	'geną',
	'genu',
	'geni',
	'genų',
	'geno',
	'gene',
	'genį',
	'geny',
	'gerų',
	'gerą',
	'geru',
	'gero',
	'geri',
	'gera',
	'gere',
	'gėrė',
	'gėrę',
	'gėrį',
	'gėri',
	'gers',
	'gerk',
	'gert',
	'geso',
	'gesę',
	'gesk',
	'gesi',
	'gest',
	'gęšė',
	'gęšę',
	'gęše',
	'getų',
	'getą',
	'getu',
	'geto',
	'gete',
	'gidų',
	'gidą',
	'gidu',
	'gido',
	'gide',
	'gidė',
	'gidę',
	'gieš',
	'gija',
	'gijų',
	'giją',
	'gijo',
	'giję',
	'gilė',
	'gilę',
	'gile',
	'gili',
	'gilį',
	'gils',
	'gilk',
	'gilt',
	'gilų',
	'gilu',
	'gimė',
	'gimę',
	'gims',
	'gimk',
	'gimt',
	'gina',
	'giną',
	'ginu',
	'gini',
	'ginė',
	'ginę',
	'gins',
	'gink',
	'gint',
	'gira',
	'girų',
	'girą',
	'giri',
	'girs',
	'girk',
	'girt',
	'gižo',
	'gižę',
	'gižk',
	'giši',
	'gižt',
	'gižų',
	'giži',
	'gižu',
	'gybo',
	'gybę',
	'gybs',
	'gybk',
	'gybt',
	'gydė',
	'gydę',
	'gydo',
	'gydą',
	'gyja',
	'gyją',
	'gyju',
	'gyji',
	'gylė',
	'gylę',
	'gyle',
	'gylį',
	'gyli',
	'gyly',
	'gymį',
	'gymi',
	'gynė',
	'gynę',
	'gyrė',
	'gyrę',
	'gysk',
	'gysi',
	'gyst',
	'gyti',
	'gytų',
	'gytą',
	'gytu',
	'gyto',
	'gyta',
	'gyvų',
	'gyvą',
	'gyvu',
	'gyvo',
	'gyvi',
	'gyva',
	'gyvį',
	'gleš',
	'glis',
	'glus',
	'gobė',
	'gobę',
	'gobi',
	'gobį',
	'gobs',
	'gobk',
	'gobt',
	'gobų',
	'gobu',
	'goda',
	'godų',
	'godą',
	'godu',
	'godo',
	'gode',
	'godi',
	'gogų',
	'gogą',
	'gogu',
	'gogo',
	'goge',
	'gojų',
	'gokt',
	'gonį',
	'gony',
	'gotų',
	'gotą',
	'gotu',
	'goto',
	'gote',
	'gotė',
	'gotę',
	'goža',
	'gožų',
	'gožą',
	'gožė',
	'gožę',
	'goži',
	'gožį',
	'gožk',
	'goši',
	'gožt',
	'grės',
	'gręs',
	'gręš',
	'gris',
	'grįs',
	'grįš',
	'gros',
	'grok',
	'grot',
	'grus',
	'grūs',
	'guba',
	'gubų',
	'gubą',
	'gubo',
	'gubę',
	'gubs',
	'gubk',
	'gubt',
	'gūdų',
	'gūdą',
	'gūdu',
	'gūdo',
	'gūde',
	'gudė',
	'gudę',
	'gude',
	'gūdį',
	'gūdi',
	'gudo',
	'guga',
	'gugų',
	'gugą',
	'gugu',
	'gugi',
	'gugė',
	'gugę',
	'guge',
	'gugo',
	'gugs',
	'gugt',
	'guis',
	'guik',
	'guit',
	'guja',
	'gują',
	'guju',
	'guji',
	'gujo',
	'guję',
	'gula',
	'gulą',
	'gulu',
	'guli',
	'gulė',
	'gulę',
	'gulį',
	'guls',
	'gulk',
	'gult',
	'guma',
	'gumų',
	'gumą',
	'guos',
	'gūra',
	'gūrų',
	'gūrą',
	'gura',
	'gurą',
	'guru',
	'guri',
	'gūru',
	'gūri',
	'gurk',
	'guro',
	'gurę',
	'gurs',
	'gurt',
	'gurų',
	'gūsį',
	'gūsi',
	'gusk',
	'gusi',
	'gust',
	'guvų',
	'guvi',
	'guvu',
	'guzų',
	'guzą',
	'guzu',
	'guzo',
	'guze',
	'guža',
	'gužą',
	'gužu',
	'guži',
	'gužų',
	'gužo',
	'guže',
	'gūžė',
	'gūžę',
	'gūže',
	'gūži',
	'gūžį',
	'gūžy',
	'gūžo',
	'gūžą',
	'gūžt',
	'gūžk',
	'gūši',
	'halė',
	'halę',
	'hale',
	'helį',
	'heli',
	'hobį',
	'hobi',
	'hunų',
	'huną',
	'hunu',
	'huno',
	'hune',
	'hunė',
	'hunę',
	'įarė',
	'įarę',
	'įari',
	'įars',
	'įark',
	'įart',
	'įauk',
	'įaus',
	'įaut',
	'įavė',
	'įavę',
	'įavų',
	'įavi',
	'įavu',
	'įbėk',
	'įbes',
	'įbos',
	'įbūk',
	'įbus',
	'įbūt',
	'įčiš',
	'įčyš',
	'įdek',
	'įdės',
	'įdėk',
	'įdėt',
	'įdyš',
	'įdūk',
	'įdus',
	'įduš',
	'įėda',
	'įėdą',
	'įėdu',
	'įėdi',
	'įėdė',
	'įėdę',
	'įeis',
	'įeik',
	'įeit',
	'įėjo',
	'įėję',
	'įėmė',
	'įėmę',
	'iena',
	'ienų',
	'ieną',
	'įėsk',
	'įėsi',
	'įėst',
	'ietį',
	'iety',
	'ieva',
	'ievų',
	'ievą',
	'įges',
	'įgiš',
	'įgis',
	'įgyk',
	'įgyt',
	'įgus',
	'įgūš',
	'įima',
	'įimą',
	'įimu',
	'įimi',
	'įims',
	'įimk',
	'įimt',
	'įiri',
	'įirs',
	'įirk',
	'įirt',
	'įyrė',
	'įyrę',
	'įjos',
	'įjok',
	'įjot',
	'įkas',
	'įkąs',
	'įkiš',
	'įkoš',
	'ikrų',
	'ikrą',
	'ikru',
	'ikro',
	'ikre',
	'ikrė',
	'ikrę',
	'įlėk',
	'ilgų',
	'ilgą',
	'ilgu',
	'ilgo',
	'ilgi',
	'ilga',
	'ilgė',
	'ilgę',
	'ilge',
	'ilgį',
	'ilgs',
	'ilgt',
	'įlįs',
	'įlis',
	'įlyk',
	'įlyt',
	'ilsi',
	'ilsį',
	'ilso',
	'ilsę',
	'ilsk',
	'ilst',
	'ilsų',
	'ilsu',
	'iltį',
	'ilty',
	'įlūš',
	'imat',
	'imąs',
	'imam',
	'įmes',
	'įmęs',
	'įmėš',
	'įmik',
	'įmiš',
	'įmyk',
	'imlų',
	'imli',
	'imlu',
	'imti',
	'imtų',
	'imtą',
	'imtu',
	'imto',
	'imsi',
	'imta',
	'imtį',
	'imty',
	'įmuk',
	'įmuš',
	'indų',
	'indą',
	'indu',
	'indo',
	'inde',
	'indė',
	'indę',
	'indį',
	'indi',
	'įneš',
	'ings',
	'įnik',
	'inkų',
	'inką',
	'inku',
	'inko',
	'inke',
	'inkė',
	'inkę',
	'inkš',
	'įpeš',
	'įpyk',
	'įpūs',
	'įpus',
	'įpūk',
	'įpūt',
	'įręs',
	'įrėš',
	'irgi',
	'iria',
	'irią',
	'iriu',
	'įris',
	'įriš',
	'irau',
	'irot',
	'iręs',
	'irus',
	'irom',
	'irai',
	'irti',
	'irtų',
	'irtą',
	'irtu',
	'irto',
	'irsi',
	'irta',
	'įrūk',
	'irių',
	'irūs',
	'įrus',
	'irzo',
	'irzę',
	'irzk',
	'irzt',
	'irzų',
	'irzi',
	'irzu',
	'įsek',
	'įsės',
	'įsėk',
	'įsėt',
	'įsuk',
	'išės',
	'išiš',
	'išnė',
	'išnę',
	'išne',
	'įšok',
	'išūš',
	'įtik',
	'itin',
	'itrį',
	'itri',
	'įtūš',
	'įves',
	'įveš',
	'įvis',
	'įvyk',
	'įvys',
	'įvyt',
	'ižas',
	'ižus',
	'ižui',
	'ižai',
	'iždų',
	'iždą',
	'iždu',
	'iždo',
	'ižde',
	'ižėt',
	'ižęs',
	'ižėm',
	'ižei',
	'ižys',
	'ižyk',
	'ižyt',
	'ižau',
	'ižot',
	'ižąs',
	'ižom',
	'ižti',
	'teiš',
	'neiš',
	'beiš',
	'ižtų',
	'išią',
	'ižtą',
	'išiu',
	'ižtu',
	'išit',
	'ižto',
	'išim',
	'ižta',
	'ižių',
	'ižią',
	'ižiu',
	'ižūs',
	'ižia',
	'ydos',
	'ydas',
	'ydom',
	'ydoj',
	'ydai',
	'ygzk',
	'ygsi',
	'ygzt',
	'ylos',
	'ylas',
	'ylom',
	'yloj',
	'ylai',
	'ylių',
	'ypač',
	'yrat',
	'yrąs',
	'yram',
	'yrėt',
	'yręs',
	'yrus',
	'yrėm',
	'yrei',
	'yris',
	'yrių',
	'yriu',
	'yrio',
	'yvas',
	'yvus',
	'yvui',
	'yvai',
	'yzgė',
	'yzgę',
	'yzgi',
	'yžta',
	'yžtą',
	'yžtu',
	'yžti',
	'jakų',
	'jaką',
	'jaku',
	'jako',
	'jake',
	'jauk',
	'jaus',
	'jaut',
	'jėga',
	'jėgų',
	'jėgą',
	'jodų',
	'jodą',
	'jodu',
	'jodo',
	'jode',
	'jodė',
	'jodę',
	'joga',
	'jogų',
	'jogą',
	'jogu',
	'jogo',
	'joge',
	'jogė',
	'jogę',
	'joja',
	'jojų',
	'joją',
	'joju',
	'joji',
	'jojo',
	'joję',
	'jonų',
	'joną',
	'jonu',
	'jono',
	'jone',
	'jotų',
	'jotą',
	'jotu',
	'joto',
	'joti',
	'jota',
	'jote',
	'josi',
	'jovė',
	'jovę',
	'juda',
	'judą',
	'judu',
	'judi',
	'juka',
	'jukų',
	'juką',
	'juko',
	'jukę',
	'jukš',
	'juks',
	'jukt',
	'juki',
	'juku',
	'junk',
	'juos',
	'jūra',
	'jūrų',
	'jūrą',
	'just',
	'jusk',
	'jusi',
	'juta',
	'jutų',
	'jutą',
	'juti',
	'jutį',
	'juto',
	'jutę',
	'kaba',
	'kabų',
	'kabą',
	'kabu',
	'kabi',
	'kabė',
	'kabę',
	'kabe',
	'kabį',
	'kaby',
	'kabo',
	'kada',
	'kaip',
	'kais',
	'kaiš',
	'kako',
	'kakę',
	'kaks',
	'kakt',
	'kala',
	'kalą',
	'kalu',
	'kali',
	'kalė',
	'kalę',
	'kale',
	'kalį',
	'kals',
	'kalk',
	'kalt',
	'kalų',
	'kame',
	'kana',
	'kanų',
	'kaną',
	'kapa',
	'kapų',
	'kapą',
	'kapu',
	'kapo',
	'kape',
	'kapt',
	'kąra',
	'kąrą',
	'kąru',
	'kąri',
	'karų',
	'karą',
	'karė',
	'karu',
	'karo',
	'kare',
	'kari',
	'karį',
	'kary',
	'kark',
	'karę',
	'kars',
	'karš',
	'kart',
	'kasa',
	'kasų',
	'kasą',
	'kasu',
	'kasi',
	'kasė',
	'kasę',
	'kaso',
	'kask',
	'kast',
	'kąsk',
	'kąsi',
	'kąst',
	'kata',
	'katą',
	'katu',
	'kati',
	'katė',
	'katę',
	'kate',
	'kauk',
	'kauš',
	'kaus',
	'kaut',
	'kava',
	'kavų',
	'kavą',
	'kaži',
	'keti',
	'keda',
	'kedą',
	'kedu',
	'kedi',
	'kėdė',
	'kėdę',
	'kėde',
	'keik',
	'keis',
	'kekė',
	'kekę',
	'keke',
	'kėlė',
	'kėlę',
	'keli',
	'kely',
	'kelį',
	'kels',
	'kelk',
	'kelt',
	'kemš',
	'kėnį',
	'kėni',
	'kenk',
	'kepa',
	'kepą',
	'kepu',
	'kepi',
	'kepė',
	'kepę',
	'kepo',
	'keps',
	'kepk',
	'kept',
	'kęra',
	'kęrą',
	'kęru',
	'kęri',
	'kerų',
	'kerą',
	'keru',
	'kero',
	'kere',
	'kerė',
	'kerę',
	'kerk',
	'keri',
	'kerį',
	'kers',
	'kert',
	'kėsk',
	'kėsi',
	'kėst',
	'kęsk',
	'kęsi',
	'kęst',
	'keta',
	'ketą',
	'ketu',
	'kėtė',
	'kėtę',
	'ketų',
	'keža',
	'kežą',
	'kežu',
	'keži',
	'kežų',
	'kežo',
	'keže',
	'kežį',
	'kežy',
	'kežę',
	'kežk',
	'keši',
	'kežt',
	'kibį',
	'kibi',
	'kibo',
	'kibę',
	'kibs',
	'kibk',
	'kibt',
	'kibų',
	'kibu',
	'kido',
	'kidę',
	'kiek',
	'kilo',
	'kilę',
	'kils',
	'kilk',
	'kilt',
	'kilų',
	'kili',
	'kilu',
	'kimo',
	'kimę',
	'kimš',
	'kims',
	'kimk',
	'kimt',
	'kimų',
	'kimi',
	'kimu',
	'kinų',
	'kiną',
	'kinu',
	'kino',
	'kine',
	'kinė',
	'kinę',
	'kinį',
	'kini',
	'kioš',
	'kirų',
	'kirą',
	'kiru',
	'kiro',
	'kire',
	'kirk',
	'kirs',
	'kirš',
	'kisk',
	'kisi',
	'kist',
	'kiša',
	'kišą',
	'kišu',
	'kiši',
	'kišo',
	'kišę',
	'kišt',
	'kišk',
	'kitų',
	'kitą',
	'kitu',
	'kito',
	'kite',
	'kitę',
	'kius',
	'kiuš',
	'kybo',
	'kybą',
	'kyla',
	'kylą',
	'kylu',
	'kyli',
	'kylį',
	'kyri',
	'kyrį',
	'kyša',
	'kyšų',
	'kyšą',
	'kyši',
	'kyšį',
	'kyšo',
	'kyšt',
	'klek',
	'klės',
	'kliš',
	'kliu',
	'klyk',
	'klys',
	'klos',
	'klok',
	'klot',
	'klus',
	'kneš',
	'knis',
	'knyk',
	'knos',
	'knok',
	'knot',
	'kodų',
	'kodą',
	'kodu',
	'kodo',
	'kode',
	'kogi',
	'koja',
	'kojų',
	'koją',
	'kokį',
	'koki',
	'kola',
	'koma',
	'kone',
	'kopa',
	'kopų',
	'kopą',
	'kopė',
	'kopę',
	'kopi',
	'kops',
	'kopk',
	'kopt',
	'korė',
	'korę',
	'korį',
	'kory',
	'kosi',
	'kosį',
	'košė',
	'košę',
	'koše',
	'koši',
	'košk',
	'košt',
	'kotų',
	'kotą',
	'kotu',
	'koto',
	'kote',
	'kova',
	'kovų',
	'kovą',
	'kovu',
	'kovo',
	'kove',
	'kovė',
	'kovę',
	'krak',
	'krek',
	'kreš',
	'krik',
	'kris',
	'kryk',
	'kruš',
	'kubų',
	'kubą',
	'kubu',
	'kubo',
	'kube',
	'kūgį',
	'kūgi',
	'kuis',
	'kūjų',
	'kūjį',
	'kūju',
	'kūjo',
	'kūji',
	'kuka',
	'kuką',
	'kuku',
	'kuki',
	'kūkų',
	'kūką',
	'kūku',
	'kūko',
	'kūke',
	'kūkė',
	'kūkę',
	'kukį',
	'kuko',
	'kukę',
	'kuks',
	'kukt',
	'kukū',
	'kūlė',
	'kūlę',
	'kūle',
	'kuli',
	'kūlį',
	'kūly',
	'kuls',
	'kulk',
	'kult',
	'kūma',
	'kūmų',
	'kūmą',
	'kūmu',
	'kūmo',
	'kūme',
	'kūnų',
	'kūną',
	'kūnu',
	'kūno',
	'kūne',
	'kupa',
	'kupą',
	'kupu',
	'kupi',
	'kūpa',
	'kūpą',
	'kūpu',
	'kūpi',
	'kupė',
	'kupę',
	'kupe',
	'kūpį',
	'kupo',
	'kups',
	'kupk',
	'kupt',
	'kurų',
	'kurą',
	'kuru',
	'kuro',
	'kure',
	'kūrė',
	'kūrę',
	'kuri',
	'kurk',
	'kurs',
	'kurt',
	'kusk',
	'kusi',
	'kust',
	'kutų',
	'kutą',
	'kutu',
	'kuto',
	'kute',
	'kūtė',
	'kūtę',
	'kūte',
	'kutę',
	'kuzė',
	'kuzę',
	'kuze',
	'kuzi',
	'kuzį',
	'kuža',
	'kužą',
	'kužu',
	'kuži',
	'kvar',
	'kvėš',
	'kvyk',
	'kvos',
	'labų',
	'labą',
	'labu',
	'labo',
	'labi',
	'laba',
	'labe',
	'laja',
	'lajų',
	'lają',
	'laji',
	'laju',
	'laka',
	'lakų',
	'laką',
	'laku',
	'laki',
	'lako',
	'lake',
	'lakė',
	'lakę',
	'laks',
	'lakt',
	'lala',
	'lalą',
	'lalu',
	'lali',
	'lama',
	'lamų',
	'lamą',
	'lapų',
	'lapą',
	'lapu',
	'lapo',
	'lape',
	'lapė',
	'lapę',
	'lapt',
	'lasė',
	'lasę',
	'laso',
	'lasą',
	'laša',
	'lašą',
	'lašu',
	'laši',
	'lašų',
	'lašo',
	'laše',
	'lašt',
	'lauk',
	'lauš',
	'lava',
	'lavų',
	'lavą',
	'lavi',
	'lavu',
	'lažų',
	'lažą',
	'lažu',
	'lažo',
	'laže',
	'ledų',
	'ledą',
	'ledu',
	'ledo',
	'lede',
	'leis',
	'lėkė',
	'lėkę',
	'leki',
	'lėkį',
	'lėki',
	'lėks',
	'lėkt',
	'lėlė',
	'lėlę',
	'lėle',
	'lėlį',
	'lėly',
	'lema',
	'lemų',
	'lemą',
	'lemu',
	'lemo',
	'leme',
	'lėmė',
	'lėmę',
	'lemi',
	'lems',
	'lemk',
	'lemt',
	'lėnų',
	'lėną',
	'lėnu',
	'lėno',
	'lėni',
	'lėna',
	'lenų',
	'leną',
	'lenu',
	'leno',
	'lene',
	'lenk',
	'lepo',
	'lepę',
	'lėpo',
	'lėpę',
	'lept',
	'leps',
	'lepk',
	'lėps',
	'lėpk',
	'lėpt',
	'lepų',
	'lepi',
	'lepu',
	'lesa',
	'lesą',
	'lesu',
	'lesi',
	'lesė',
	'lesę',
	'lėsk',
	'lėsi',
	'lėst',
	'lesk',
	'lest',
	'lesų',
	'lęšį',
	'lęši',
	'lėtų',
	'lėtą',
	'lėtu',
	'lėto',
	'lėti',
	'lėta',
	'lėtę',
	'lies',
	'liek',
	'liet',
	'lieš',
	'liga',
	'ligų',
	'ligą',
	'ligi',
	'lijo',
	'liję',
	'liko',
	'likę',
	'liks',
	'likt',
	'limo',
	'limę',
	'lims',
	'limk',
	'limt',
	'linų',
	'liną',
	'linu',
	'lino',
	'line',
	'link',
	'lipa',
	'lipą',
	'lipu',
	'lipi',
	'lipė',
	'lipę',
	'lipo',
	'lips',
	'lipk',
	'lipt',
	'lipų',
	'lira',
	'lirų',
	'lirą',
	'lįsk',
	'lįsi',
	'lįst',
	'litų',
	'litą',
	'litu',
	'lito',
	'lite',
	'litį',
	'liti',
	'liūs',
	'ližė',
	'ližę',
	'liže',
	'ližt',
	'lydė',
	'lydę',
	'lydi',
	'lydį',
	'lydy',
	'lydo',
	'lydą',
	'lydų',
	'lydu',
	'lyga',
	'lygų',
	'lygą',
	'lygė',
	'lygę',
	'lyge',
	'lygį',
	'lygi',
	'lygo',
	'lygs',
	'lygt',
	'lygu',
	'lyja',
	'lyją',
	'lyju',
	'lyji',
	'lynų',
	'lyną',
	'lynu',
	'lyno',
	'lyne',
	'lypi',
	'lyra',
	'lyrų',
	'lyrą',
	'lysė',
	'lysę',
	'lyse',
	'lyso',
	'lysk',
	'lysi',
	'lyst',
	'lyta',
	'lytų',
	'lytą',
	'lyti',
	'lytį',
	'lytu',
	'lyto',
	'lyty',
	'lyžį',
	'lyži',
	'lyžo',
	'lyžę',
	'lyžt',
	'lyžk',
	'lyši',
	'lobį',
	'lobi',
	'lobo',
	'lobę',
	'lobs',
	'lobk',
	'lobt',
	'lobų',
	'lobu',
	'lodė',
	'lodę',
	'lodo',
	'lodą',
	'loja',
	'loją',
	'loju',
	'loji',
	'lojo',
	'loję',
	'lokė',
	'lokę',
	'loke',
	'lokį',
	'loky',
	'loma',
	'lomų',
	'lomą',
	'lomi',
	'lomu',
	'lopų',
	'lopą',
	'lopu',
	'lopo',
	'lope',
	'lopė',
	'lopę',
	'lošė',
	'lošę',
	'loši',
	'lošk',
	'lošt',
	'loti',
	'lotų',
	'lotą',
	'lotu',
	'loto',
	'losi',
	'lota',
	'lova',
	'lovų',
	'lovą',
	'lovį',
	'lovy',
	'ložė',
	'ložę',
	'lože',
	'lūki',
	'lūkį',
	'luoš',
	'lupa',
	'lupų',
	'lupą',
	'lūpa',
	'lūpų',
	'lūpą',
	'lupu',
	'lupi',
	'lupo',
	'lupę',
	'lups',
	'lupk',
	'lupt',
	'lūšį',
	'lūšy',
	'lūža',
	'lūžų',
	'lūžą',
	'lūži',
	'lūžį',
	'lūžo',
	'lūžę',
	'lūžk',
	'lūši',
	'lūžt',
	'lūžu',
	'macų',
	'macą',
	'macu',
	'maco',
	'mace',
	'mačų',
	'mačą',
	'maču',
	'mačo',
	'mače',
	'mada',
	'madų',
	'madą',
	'maga',
	'magą',
	'magu',
	'magi',
	'magų',
	'mago',
	'mage',
	'magė',
	'magę',
	'majų',
	'mają',
	'maju',
	'majo',
	'majė',
	'maję',
	'maje',
	'makų',
	'maką',
	'maku',
	'mako',
	'make',
	'makt',
	'mala',
	'malą',
	'malu',
	'mali',
	'malė',
	'malę',
	'malš',
	'mals',
	'malk',
	'malt',
	'mama',
	'mamų',
	'mamą',
	'mana',
	'manų',
	'maną',
	'manė',
	'manę',
	'mano',
	'marų',
	'marą',
	'maru',
	'maro',
	'mare',
	'mark',
	'marš',
	'mari',
	'masė',
	'masę',
	'mase',
	'matų',
	'matą',
	'matu',
	'mato',
	'mate',
	'matė',
	'matę',
	'mati',
	'mauk',
	'maus',
	'maut',
	'mažų',
	'mažą',
	'mažu',
	'mažo',
	'maži',
	'maža',
	'mažę',
	'mažė',
	'maši',
	'medį',
	'medi',
	'medų',
	'mėgo',
	'mėgę',
	'mėgs',
	'mėgt',
	'megs',
	'meiš',
	'mekų',
	'meką',
	'meku',
	'meko',
	'meke',
	'melų',
	'melą',
	'melu',
	'melo',
	'mele',
	'mėlį',
	'mėli',
	'mėlo',
	'mėlę',
	'mels',
	'mėls',
	'mėlk',
	'mėlt',
	'melš',
	'mėmė',
	'mėmę',
	'mėme',
	'mena',
	'meną',
	'menu',
	'meni',
	'menų',
	'meno',
	'mene',
	'menė',
	'menę',
	'menk',
	'merų',
	'merą',
	'meru',
	'mero',
	'mere',
	'merė',
	'merę',
	'merk',
	'mėsa',
	'mėsų',
	'mėsą',
	'mėsė',
	'mėsę',
	'mėse',
	'męsk',
	'męsi',
	'męst',
	'mesk',
	'mesi',
	'mest',
	'mėta',
	'mėtų',
	'mėtą',
	'meta',
	'metą',
	'metu',
	'meti',
	'metų',
	'meto',
	'mete',
	'mėtė',
	'mėtę',
	'metė',
	'metę',
	'mėtį',
	'mėti',
	'mėto',
	'mėžė',
	'mėžę',
	'mėžk',
	'mėši',
	'mėžt',
	'midų',
	'mieš',
	'migį',
	'migi',
	'migo',
	'migę',
	'migt',
	'migs',
	'mikt',
	'milų',
	'milą',
	'milu',
	'milo',
	'mile',
	'milš',
	'mina',
	'minų',
	'miną',
	'minė',
	'minę',
	'mini',
	'minį',
	'mins',
	'mink',
	'mint',
	'mirė',
	'mirę',
	'mirk',
	'mirš',
	'mirs',
	'mirt',
	'misa',
	'misų',
	'misą',
	'misk',
	'misi',
	'mist',
	'įmis',
	'mišė',
	'mišę',
	'mišo',
	'mišą',
	'mišk',
	'miši',
	'mišt',
	'mitų',
	'mitą',
	'mitu',
	'mito',
	'mite',
	'mitę',
	'myga',
	'mygą',
	'mygu',
	'mygi',
	'mygo',
	'mygę',
	'mygt',
	'mygs',
	'mykė',
	'mykę',
	'myki',
	'mykt',
	'myks',
	'myli',
	'mylį',
	'mylu',
	'mylū',
	'mynė',
	'mynę',
	'myne',
	'myrį',
	'myri',
	'moja',
	'moją',
	'moju',
	'moji',
	'mojų',
	'mojį',
	'mojo',
	'moję',
	'moka',
	'mokų',
	'moką',
	'moku',
	'moki',
	'mokė',
	'mokę',
	'moko',
	'molų',
	'molą',
	'molu',
	'molo',
	'mole',
	'molė',
	'molę',
	'molį',
	'moli',
	'morė',
	'moša',
	'mošų',
	'mošą',
	'motė',
	'motę',
	'mote',
	'moti',
	'motų',
	'motą',
	'motu',
	'moto',
	'mosi',
	'mota',
	'mova',
	'movų',
	'movą',
	'movė',
	'movę',
	'movi',
	'movį',
	'mugė',
	'mugę',
	'muge',
	'mūkė',
	'mūkę',
	'mūki',
	'muko',
	'mukę',
	'mukt',
	'mūkt',
	'muks',
	'mūks',
	'mula',
	'mulų',
	'mulą',
	'mulu',
	'mulo',
	'mule',
	'mūra',
	'mūrą',
	'mūru',
	'mūri',
	'mūrų',
	'mūro',
	'mūre',
	'murk',
	'muro',
	'murę',
	'murs',
	'murt',
	'murų',
	'muri',
	'muru',
	'musų',
	'musą',
	'musu',
	'muso',
	'muse',
	'musė',
	'musę',
	'mūša',
	'mūšų',
	'mūšą',
	'muša',
	'mušą',
	'mušu',
	'muši',
	'mušė',
	'mušę',
	'mūšį',
	'mūši',
	'mušk',
	'mušt',
	'mūvi',
	'mūvį',
	'mūza',
	'mūzų',
	'mūzą',
	'nacė',
	'nacę',
	'nace',
	'nacį',
	'naci',
	'naga',
	'nagų',
	'nagą',
	'nagu',
	'nago',
	'nage',
	'nagi',
	'namų',
	'namą',
	'namu',
	'namo',
	'name',
	'narų',
	'narą',
	'naru',
	'naro',
	'nare',
	'narė',
	'narę',
	'narį',
	'nary',
	'nari',
	'naša',
	'našų',
	'našą',
	'naši',
	'našu',
	'nata',
	'natų',
	'natą',
	'nava',
	'navų',
	'navą',
	'nebe',
	'nėgė',
	'nėgę',
	'nėge',
	'negu',
	'neik',
	'nėrė',
	'nėrę',
	'neri',
	'nerš',
	'ners',
	'nerk',
	'nert',
	'neša',
	'nešą',
	'nešu',
	'neši',
	'nešė',
	'nešę',
	'nėšį',
	'nėši',
	'nešk',
	'nešt',
	'neto',
	'neva',
	'nigs',
	'nikį',
	'niki',
	'niko',
	'nikę',
	'niks',
	'nikt',
	'niro',
	'nirę',
	'nirs',
	'nirš',
	'nirk',
	'nirt',
	'niša',
	'nišų',
	'nišą',
	'nitų',
	'nitą',
	'nitu',
	'nito',
	'nite',
	'niuk',
	'niūk',
	'niuo',
	'nižo',
	'nižę',
	'nižk',
	'niši',
	'nižt',
	'nižų',
	'niži',
	'nižu',
	'nyka',
	'nykų',
	'nyką',
	'nyko',
	'nykę',
	'nyks',
	'nykt',
	'nyki',
	'nyku',
	'nyra',
	'nyrą',
	'nyru',
	'nyri',
	'nyrį',
	'nyro',
	'noko',
	'nokę',
	'noks',
	'nokt',
	'nokų',
	'noki',
	'noku',
	'norų',
	'norą',
	'noru',
	'noro',
	'nore',
	'nori',
	'norį',
	'norę',
	'nors',
	'nork',
	'nort',
	'nosį',
	'nosy',
	'nota',
	'notų',
	'notą',
	'nūdo',
	'nūdę',
	'nuės',
	'nulį',
	'nuli',
	'nūsk',
	'nūsi',
	'nūst',
	'nuūš',
	'oazė',
	'oazę',
	'oaze',
	'odos',
	'odas',
	'odom',
	'odoj',
	'odai',
	'odes',
	'odės',
	'odėm',
	'odėj',
	'odei',
	'olos',
	'olas',
	'olom',
	'oloj',
	'olai',
	'olia',
	'omas',
	'omus',
	'omui',
	'omai',
	'omių',
	'omes',
	'omės',
	'omėm',
	'omėj',
	'omei',
	'opos',
	'opas',
	'opom',
	'opoj',
	'opai',
	'opės',
	'opėk',
	'opėt',
	'opus',
	'opių',
	'opią',
	'opiu',
	'opūs',
	'opia',
	'oras',
	'orus',
	'orui',
	'orai',
	'orda',
	'ordų',
	'ordą',
	'orių',
	'ores',
	'orės',
	'orėm',
	'orėj',
	'orei',
	'orėk',
	'orėt',
	'oriu',
	'orit',
	'orįs',
	'orim',
	'orią',
	'orūs',
	'oria',
	'osmį',
	'osmi',
	'ošėt',
	'ošęs',
	'ošus',
	'ošėm',
	'ošei',
	'ošia',
	'ošią',
	'ošiu',
	'ošlė',
	'ošlę',
	'ošle',
	'ošlį',
	'ošly',
	'ošti',
	'teoš',
	'neoš',
	'beoš',
	'oštų',
	'oštą',
	'oštu',
	'ošit',
	'ošto',
	'ošim',
	'ošta',
	'otas',
	'otus',
	'otui',
	'otai',
	'ozas',
	'ozus',
	'ozui',
	'ozai',
	'ožys',
	'ožių',
	'ožiu',
	'ožio',
	'ožka',
	'ožkų',
	'ožką',
	'padų',
	'padą',
	'padu',
	'pado',
	'pade',
	'paės',
	'paik',
	'pajų',
	'pakų',
	'paką',
	'paku',
	'pako',
	'pake',
	'pala',
	'palų',
	'palą',
	'palu',
	'palo',
	'pale',
	'palš',
	'pana',
	'panų',
	'paną',
	'pano',
	'papų',
	'papą',
	'papu',
	'papo',
	'pape',
	'papt',
	'para',
	'parų',
	'parą',
	'pasų',
	'pasą',
	'pasu',
	'paso',
	'pase',
	'paša',
	'pašų',
	'pašą',
	'pašė',
	'pašę',
	'pašo',
	'patų',
	'patą',
	'patu',
	'pato',
	'pate',
	'pati',
	'pats',
	'paūš',
	'pažų',
	'pažą',
	'pažu',
	'pažo',
	'paže',
	'pėda',
	'pėdų',
	'pėdą',
	'pėdu',
	'pėdo',
	'pėde',
	'pėdė',
	'pėdę',
	'peik',
	'pelų',
	'pelą',
	'pelu',
	'pelo',
	'pele',
	'pelė',
	'pelę',
	'penų',
	'peną',
	'penu',
	'peno',
	'pene',
	'peni',
	'penį',
	'pėra',
	'pėrų',
	'pėrą',
	'perų',
	'perą',
	'peru',
	'pero',
	'pere',
	'pėrė',
	'pėrę',
	'peri',
	'perį',
	'pers',
	'perk',
	'pert',
	'peša',
	'pešą',
	'pešu',
	'peši',
	'pešė',
	'pešę',
	'pešt',
	'pešk',
	'petį',
	'pety',
	'pieš',
	'pigo',
	'pigę',
	'pigs',
	'pigt',
	'pigų',
	'pigi',
	'pigu',
	'pikų',
	'piką',
	'piku',
	'piko',
	'pike',
	'pikį',
	'piki',
	'pila',
	'pilą',
	'pilu',
	'pili',
	'pilį',
	'pily',
	'pilk',
	'pils',
	'pilt',
	'pina',
	'piną',
	'pinu',
	'pini',
	'pinų',
	'pino',
	'pine',
	'pins',
	'pink',
	'pint',
	'pipt',
	'pirk',
	'pirm',
	'pirš',
	'pydė',
	'pydę',
	'pydo',
	'pydą',
	'pygį',
	'pygi',
	'pyka',
	'pykų',
	'pyką',
	'pyko',
	'pykę',
	'pyks',
	'pykt',
	'pyla',
	'pylų',
	'pylą',
	'pylė',
	'pylę',
	'pynė',
	'pynę',
	'pyne',
	'pyni',
	'pynį',
	'pypė',
	'pypę',
	'pypi',
	'pypt',
	'pyps',
	'pypk',
	'plak',
	'plėk',
	'plės',
	'plėš',
	'plik',
	'plis',
	'plyš',
	'plos',
	'plok',
	'plot',
	'pluk',
	'plūk',
	'plūs',
	'pluš',
	'polį',
	'poli',
	'ponų',
	'poną',
	'ponu',
	'pono',
	'pone',
	'ponį',
	'poni',
	'popa',
	'popų',
	'popą',
	'pora',
	'porų',
	'porą',
	'poru',
	'poro',
	'pore',
	'povų',
	'povą',
	'povu',
	'povo',
	'pove',
	'povė',
	'povę',
	'poza',
	'pozų',
	'pozą',
	'pras',
	'prie',
	'prus',
	'pučų',
	'pučą',
	'puču',
	'pučo',
	'puče',
	'puti',
	'pūdų',
	'pūdą',
	'pūdu',
	'pūdo',
	'pūde',
	'pūdė',
	'pūdę',
	'pūga',
	'pūgų',
	'pūgą',
	'puik',
	'pūkė',
	'pūkę',
	'pūke',
	'pūkį',
	'pūki',
	'pūky',
	'pukš',
	'pūkš',
	'puls',
	'pulk',
	'pult',
	'puma',
	'pumų',
	'pumą',
	'puoš',
	'pupa',
	'pupų',
	'pupą',
	'pupt',
	'pūpt',
	'pūra',
	'pūrą',
	'pūru',
	'pūri',
	'pūrų',
	'pūro',
	'pūre',
	'purė',
	'purę',
	'pure',
	'purį',
	'puri',
	'puro',
	'purs',
	'purk',
	'purt',
	'purų',
	'puru',
	'pusė',
	'pusę',
	'puse',
	'pūst',
	'pusk',
	'pusi',
	'pust',
	'pūsk',
	'pūsi',
	'pušų',
	'pušį',
	'pušy',
	'puta',
	'putų',
	'putą',
	'putė',
	'putę',
	'pute',
	'pūtė',
	'pūtę',
	'pūti',
	'pūtų',
	'pūtą',
	'pūtu',
	'pūto',
	'pūta',
	'pūtį',
	'puto',
	'pūva',
	'pūvą',
	'pūvu',
	'pūvi',
	'puvo',
	'puvę',
	'puvų',
	'puvi',
	'puvu',
	'pužų',
	'pužą',
	'pužu',
	'pužo',
	'puže',
	'radį',
	'radi',
	'rado',
	'radę',
	'ragų',
	'ragą',
	'ragu',
	'rago',
	'rage',
	'ragė',
	'ragę',
	'ragį',
	'ragi',
	'ragy',
	'raiš',
	'raja',
	'rajų',
	'rają',
	'raji',
	'raju',
	'raka',
	'raką',
	'raku',
	'raki',
	'rakė',
	'rakę',
	'rakį',
	'raky',
	'rako',
	'rakt',
	'raks',
	'ralį',
	'rali',
	'ramų',
	'rami',
	'ramu',
	'rasa',
	'rasų',
	'rasą',
	'rasė',
	'rasę',
	'rase',
	'rasi',
	'rask',
	'rast',
	'rašė',
	'rašę',
	'rašo',
	'rašą',
	'rata',
	'ratų',
	'ratą',
	'ratu',
	'rato',
	'rate',
	'rauk',
	'raus',
	'raut',
	'ravi',
	'ravį',
	'ražų',
	'ražą',
	'ražu',
	'ražo',
	'raže',
	'rega',
	'regų',
	'regą',
	'regi',
	'regį',
	'regu',
	'regs',
	'reik',
	'rėja',
	'rėjų',
	'rėją',
	'rėkė',
	'rėkę',
	'rėki',
	'rėkš',
	'rėks',
	'rėkt',
	'rėkų',
	'rėku',
	'relė',
	'relę',
	'rele',
	'rėmų',
	'rėmą',
	'rėmu',
	'rėmo',
	'rėme',
	'rėmė',
	'rėmę',
	'remi',
	'rems',
	'remk',
	'remt',
	'remš',
	'renk',
	'renį',
	'reni',
	'rėpė',
	'rėpę',
	'rėpi',
	'rėps',
	'rėpk',
	'rėpt',
	'ręsk',
	'ręsi',
	'ręst',
	'retų',
	'retą',
	'retu',
	'reto',
	'reti',
	'reta',
	'rėtį',
	'rėti',
	'retį',
	'rety',
	'rėva',
	'rėvų',
	'rėvą',
	'rėža',
	'rėžų',
	'rėžą',
	'rėžė',
	'rėžę',
	'ręžė',
	'ręžę',
	'rėžį',
	'rėži',
	'rėžo',
	'rėžk',
	'rėši',
	'rėžt',
	'ręžk',
	'ręši',
	'ręžt',
	'riba',
	'ribų',
	'ribą',
	'ribu',
	'ribi',
	'rida',
	'ridų',
	'ridą',
	'ridu',
	'ridi',
	'riek',
	'ries',
	'riet',
	'rifų',
	'rifą',
	'rifu',
	'rifo',
	'rife',
	'rigs',
	'rijo',
	'riję',
	'rikė',
	'rikę',
	'rike',
	'rikį',
	'riki',
	'riko',
	'riks',
	'rikt',
	'rimų',
	'rimą',
	'rimu',
	'rimo',
	'rime',
	'rimę',
	'rims',
	'rimk',
	'rimt',
	'rink',
	'risk',
	'risi',
	'rist',
	'riša',
	'rišą',
	'rišu',
	'riši',
	'rišo',
	'rišę',
	'rišk',
	'rišt',
	'rita',
	'ritą',
	'ritu',
	'riti',
	'ritė',
	'ritę',
	'rite',
	'rito',
	'riūk',
	'rizė',
	'rizę',
	'rize',
	'rydė',
	'rydę',
	'rydo',
	'rydą',
	'ryja',
	'ryją',
	'ryju',
	'ryji',
	'rykų',
	'ryką',
	'ryku',
	'ryko',
	'ryke',
	'rykš',
	'ryla',
	'rylų',
	'rylą',
	'ryma',
	'rymų',
	'rymą',
	'rymo',
	'ryši',
	'ryšį',
	'ryšy',
	'rytų',
	'rytą',
	'rytu',
	'ryto',
	'ryte',
	'ryti',
	'rysi',
	'ryta',
	'rytį',
	'ryty',
	'ryžį',
	'ryži',
	'ryžo',
	'ryžę',
	'ryžk',
	'ryžt',
	'rodė',
	'rodę',
	'rodį',
	'rodi',
	'rodo',
	'rodą',
	'rojų',
	'rokų',
	'roką',
	'roku',
	'roko',
	'roke',
	'rolė',
	'rolę',
	'role',
	'romų',
	'romą',
	'romė',
	'romę',
	'romu',
	'romo',
	'rome',
	'romi',
	'ropė',
	'ropę',
	'rope',
	'rovė',
	'rovę',
	'rovų',
	'rovi',
	'rovu',
	'rožė',
	'rožę',
	'rože',
	'rūbų',
	'rūbą',
	'rūbu',
	'rūbo',
	'rūbe',
	'rūda',
	'rūdų',
	'rūdą',
	'rudų',
	'rudą',
	'rudu',
	'rudo',
	'rudi',
	'ruda',
	'rudė',
	'rudę',
	'rude',
	'rūdė',
	'rūdę',
	'rūde',
	'rudį',
	'rūgi',
	'rūgį',
	'rugį',
	'rugy',
	'rūgo',
	'rūgę',
	'rūgs',
	'rūgt',
	'ruja',
	'rujų',
	'rują',
	'rūkų',
	'rūką',
	'rūku',
	'rūko',
	'rūke',
	'rūkė',
	'rūkę',
	'ruko',
	'rukę',
	'ruks',
	'rukt',
	'rūks',
	'rūkt',
	'rūmų',
	'rūmą',
	'rūmu',
	'rūmo',
	'rūme',
	'rumš',
	'runa',
	'runų',
	'runą',
	'runk',
	'ruoš',
	'rūpa',
	'rūpų',
	'rūpą',
	'rūpi',
	'rūpį',
	'rupo',
	'rupę',
	'rups',
	'rupk',
	'rupt',
	'rupų',
	'rupi',
	'rupu',
	'rūpu',
	'rusa',
	'rusą',
	'rusu',
	'rusi',
	'rusų',
	'ruso',
	'ruse',
	'rusė',
	'rusę',
	'rūsį',
	'rūsy',
	'rūso',
	'rūsą',
	'rusk',
	'rust',
	'rūšį',
	'rūšy',
	'rūta',
	'rūtų',
	'rūtą',
	'saga',
	'sagų',
	'sagą',
	'sagė',
	'sagę',
	'sage',
	'sakė',
	'sakę',
	'sako',
	'saką',
	'sakų',
	'saki',
	'saku',
	'sala',
	'salų',
	'salą',
	'sąla',
	'sąlą',
	'sąlu',
	'sąli',
	'salė',
	'salę',
	'sale',
	'salo',
	'sals',
	'salk',
	'salt',
	'samų',
	'sami',
	'samu',
	'saus',
	'sava',
	'savų',
	'savą',
	'savu',
	'savo',
	'savi',
	'save',
	'sėda',
	'sėdą',
	'sėdu',
	'sėdi',
	'sėdį',
	'sėdo',
	'sėdę',
	'sega',
	'segą',
	'segu',
	'segi',
	'segė',
	'segę',
	'sege',
	'segį',
	'segs',
	'segt',
	'sėja',
	'sėjų',
	'sėją',
	'sėju',
	'sėji',
	'sėjo',
	'sėję',
	'seka',
	'sekų',
	'seką',
	'seku',
	'seki',
	'sekė',
	'sekę',
	'seko',
	'seks',
	'sekt',
	'sėmė',
	'sėmę',
	'sėme',
	'semi',
	'sems',
	'semk',
	'semt',
	'senų',
	'seną',
	'senu',
	'seno',
	'seni',
	'sena',
	'senė',
	'senę',
	'sene',
	'senį',
	'sens',
	'senk',
	'sent',
	'sesė',
	'sesę',
	'sese',
	'sėsk',
	'sėsi',
	'sėst',
	'setų',
	'setą',
	'setu',
	'seto',
	'sete',
	'sėti',
	'sėtų',
	'sėtą',
	'sėtu',
	'sėto',
	'sėta',
	'siek',
	'sies',
	'siet',
	'sija',
	'sijų',
	'siją',
	'sijo',
	'siję',
	'sirų',
	'sirą',
	'siru',
	'siro',
	'sire',
	'sirė',
	'sirę',
	'sirk',
	'sius',
	'siųs',
	'siūs',
	'siūk',
	'siūt',
	'syja',
	'syją',
	'syju',
	'syji',
	'syjį',
	'sykų',
	'syką',
	'syku',
	'syko',
	'syke',
	'sykį',
	'syki',
	'syti',
	'sytų',
	'sytą',
	'sytu',
	'syto',
	'sysi',
	'syta',
	'skas',
	'skęs',
	'skės',
	'skis',
	'skys',
	'skus',
	'skųs',
	'slėk',
	'slys',
	'slūk',
	'smek',
	'smik',
	'smok',
	'smuk',
	'snik',
	'snūs',
	'soda',
	'sodų',
	'sodą',
	'sodu',
	'sodo',
	'sode',
	'sofa',
	'sofų',
	'sofą',
	'soja',
	'sojų',
	'soją',
	'solo',
	'sopa',
	'sopą',
	'sopu',
	'sopi',
	'sopo',
	'sopę',
	'sops',
	'sopk',
	'sopt',
	'sopų',
	'sora',
	'sorų',
	'sorą',
	'sotį',
	'soty',
	'sotų',
	'soti',
	'sotu',
	'spęs',
	'spės',
	'spėk',
	'spėt',
	'spįs',
	'spis',
	'srus',
	'srūk',
	'srūt',
	'stik',
	'styk',
	'stok',
	'stop',
	'stos',
	'stot',
	'sūdė',
	'sūdę',
	'sūdo',
	'sūdą',
	'suės',
	'suiš',
	'suka',
	'suką',
	'suku',
	'suki',
	'sūkį',
	'sūki',
	'suko',
	'sukę',
	'suks',
	'sukt',
	'sula',
	'sulų',
	'sulą',
	'suma',
	'sumų',
	'sumą',
	'sunk',
	'sūnų',
	'suok',
	'suos',
	'suoš',
	'supa',
	'supą',
	'supu',
	'supi',
	'supo',
	'supę',
	'sups',
	'supk',
	'supt',
	'sūri',
	'sūrį',
	'sūro',
	'sūrę',
	'sūrs',
	'sūrk',
	'sūrt',
	'sūrų',
	'sūru',
	'susų',
	'susą',
	'susu',
	'suso',
	'suse',
	'susę',
	'susk',
	'susi',
	'sust',
	'suūk',
	'suuš',
	'suūš',
	'svis',
	'šaka',
	'šakų',
	'šaką',
	'šakė',
	'šakę',
	'šake',
	'šakį',
	'šaky',
	'šąla',
	'šąlą',
	'šąlu',
	'šąli',
	'šalį',
	'šaly',
	'šalo',
	'šalę',
	'šals',
	'šalk',
	'šalt',
	'šamų',
	'šamą',
	'šamu',
	'šamo',
	'šame',
	'šapų',
	'šapą',
	'šapu',
	'šapo',
	'šape',
	'šast',
	'šašų',
	'šašą',
	'šašu',
	'šašo',
	'šaše',
	'šašė',
	'šašę',
	'šašk',
	'šaši',
	'šašt',
	'šauk',
	'šaus',
	'šaut',
	'šaža',
	'šažą',
	'šažu',
	'šaži',
	'šefų',
	'šefą',
	'šefu',
	'šefo',
	'šefe',
	'šėkų',
	'šėką',
	'šėku',
	'šėko',
	'šėke',
	'šėla',
	'šėlų',
	'šėlą',
	'šėlu',
	'šėlo',
	'šėle',
	'šėlę',
	'šėls',
	'šėlk',
	'šėlt',
	'šėmų',
	'šėmą',
	'šėmu',
	'šėmo',
	'šėmi',
	'šėma',
	'šėmė',
	'šėmę',
	'šėme',
	'šėmį',
	'šėpa',
	'šėpų',
	'šėpą',
	'šėpu',
	'šėpo',
	'šėpe',
	'šepo',
	'šepę',
	'šeps',
	'šepk',
	'šept',
	'šėrė',
	'šėrę',
	'šeri',
	'šerį',
	'šery',
	'šers',
	'šerk',
	'šert',
	'šiek',
	'šilų',
	'šilą',
	'šilu',
	'šilo',
	'šile',
	'šilę',
	'šils',
	'šilk',
	'šilt',
	'šika',
	'šiko',
	'šipo',
	'šipę',
	'šipt',
	'šips',
	'šipk',
	'širs',
	'širš',
	'šydų',
	'šydą',
	'šydu',
	'šydo',
	'šyde',
	'šyla',
	'šylą',
	'šylu',
	'šyli',
	'šypt',
	'šyvų',
	'šyvą',
	'šyvu',
	'šyvo',
	'šyvi',
	'šyva',
	'šyvė',
	'šyvę',
	'šyve',
	'šyvį',
	'škac',
	'škač',
	'škic',
	'šlėk',
	'šlis',
	'šlyk',
	'šlyt',
	'šoka',
	'šoką',
	'šoku',
	'šoki',
	'šokų',
	'šoko',
	'šoke',
	'šokį',
	'šokę',
	'šoks',
	'šokt',
	'šonų',
	'šoną',
	'šonu',
	'šono',
	'šone',
	'šova',
	'šovų',
	'šovą',
	'šovė',
	'šovę',
	'štai',
	'štiš',
	'šūka',
	'šūkų',
	'šūką',
	'šukė',
	'šukę',
	'šuke',
	'šūkį',
	'šūki',
	'šulų',
	'šulą',
	'šulu',
	'šulo',
	'šule',
	'šulė',
	'šulę',
	'šūst',
	'šusk',
	'šusi',
	'šust',
	'įšus',
	'šutų',
	'šutą',
	'šutu',
	'šuto',
	'šute',
	'šutę',
	'šuva',
	'šuvų',
	'šuvą',
	'šūvį',
	'šūvi',
	'švęs',
	'švis',
	'tabu',
	'tada',
	'taip',
	'tajų',
	'tają',
	'taju',
	'tajo',
	'tajė',
	'taję',
	'taje',
	'takų',
	'taką',
	'taku',
	'tako',
	'take',
	'talį',
	'tali',
	'tams',
	'tanų',
	'taną',
	'tanu',
	'tano',
	'tane',
	'tani',
	'tapė',
	'tapę',
	'tapo',
	'tapą',
	'taps',
	'tapk',
	'tapt',
	'tapu',
	'tara',
	'tarų',
	'tarą',
	'tarė',
	'tarę',
	'tari',
	'tarp',
	'tars',
	'tark',
	'tart',
	'tąsa',
	'tąsų',
	'tąsą',
	'tąsė',
	'tąsę',
	'tąso',
	'tąsi',
	'tąsu',
	'tašų',
	'tašą',
	'tašu',
	'tašo',
	'taše',
	'tašė',
	'tašę',
	'taus',
	'tegu',
	'teik',
	'teis',
	'teka',
	'teką',
	'teku',
	'teki',
	'tekį',
	'teko',
	'tekę',
	'tėkš',
	'teks',
	'tekt',
	'tekų',
	'telk',
	'telš',
	'tema',
	'temų',
	'temą',
	'temo',
	'temę',
	'tems',
	'temk',
	'temt',
	'tena',
	'teną',
	'tenu',
	'teni',
	'tepa',
	'tepą',
	'tepu',
	'tepi',
	'tepė',
	'tepę',
	'tėpį',
	'tėpi',
	'teps',
	'tepk',
	'tept',
	'tepų',
	'tėra',
	'terš',
	'tęsė',
	'tęsę',
	'tesi',
	'tesį',
	'tęsi',
	'tęsk',
	'tęst',
	'tesu',
	'teta',
	'tetų',
	'tetą',
	'tėtė',
	'tėtę',
	'tėte',
	'tėtį',
	'tėti',
	'tėvų',
	'tėvą',
	'tėvu',
	'tėvo',
	'tėve',
	'tezė',
	'tezę',
	'teze',
	'teža',
	'težą',
	'težu',
	'teži',
	'težo',
	'težę',
	'težk',
	'teši',
	'težt',
	'tfiu',
	'tiek',
	'ties',
	'tiki',
	'tikį',
	'tiko',
	'tikę',
	'tikš',
	'tiks',
	'tikt',
	'tilo',
	'tilę',
	'tils',
	'tilk',
	'tilt',
	'tilš',
	'tina',
	'tiną',
	'tinu',
	'tini',
	'tink',
	'tino',
	'tinę',
	'tins',
	'tint',
	'tipų',
	'tipą',
	'tipu',
	'tipo',
	'tipe',
	'tirų',
	'tirą',
	'tiru',
	'tiro',
	'tire',
	'tiri',
	'tirs',
	'tirk',
	'tirt',
	'tįsa',
	'tįsų',
	'tįsą',
	'tisų',
	'tisą',
	'tisu',
	'tiso',
	'tise',
	'tįsi',
	'tįsį',
	'tįso',
	'tįsę',
	'tįst',
	'tįsk',
	'tįsu',
	'tižė',
	'tižę',
	'tiže',
	'tižo',
	'tižk',
	'tiši',
	'tižt',
	'tižų',
	'tiži',
	'tižu',
	'tyka',
	'tykų',
	'tyką',
	'tyko',
	'tykę',
	'tyks',
	'tykt',
	'tyki',
	'tyku',
	'tyla',
	'tylų',
	'tylą',
	'tylu',
	'tyli',
	'tylį',
	'tymų',
	'tymą',
	'tymu',
	'tymo',
	'tyme',
	'tyna',
	'tynų',
	'tyną',
	'tynė',
	'tynę',
	'tynį',
	'tyni',
	'tyrų',
	'tyrą',
	'tyru',
	'tyro',
	'tyri',
	'tyra',
	'tyre',
	'tyrė',
	'tyrę',
	'tyso',
	'tysą',
	'tobį',
	'tobi',
	'toga',
	'togų',
	'togą',
	'toli',
	'tolį',
	'tolo',
	'tolę',
	'tols',
	'tolk',
	'tolt',
	'tolų',
	'tolu',
	'tomų',
	'tomą',
	'tomu',
	'tomo',
	'tome',
	'tona',
	'tonų',
	'toną',
	'tonu',
	'tono',
	'tone',
	'topt',
	'torį',
	'tori',
	'tošį',
	'tošy',
	'trės',
	'treš',
	'tręš',
	'trik',
	'trio',
	'truk',
	'trūk',
	'tūba',
	'tūbų',
	'tūbą',
	'tūbu',
	'tūbo',
	'tūbe',
	'tūbė',
	'tūbę',
	'tufų',
	'tufą',
	'tufu',
	'tufo',
	'tufe',
	'tuko',
	'tukę',
	'tuks',
	'tukt',
	'tūlų',
	'tūlą',
	'tūlu',
	'tūlo',
	'tūli',
	'tūla',
	'tulį',
	'tuli',
	'tūlį',
	'tulš',
	'tunų',
	'tuną',
	'tunu',
	'tuno',
	'tune',
	'tūno',
	'tūną',
	'tūnų',
	'tūni',
	'tūnu',
	'tuoj',
	'tuok',
	'tūpė',
	'tūpę',
	'tupi',
	'tupį',
	'tūpi',
	'tupt',
	'tūpt',
	'tūps',
	'tūpk',
	'tupu',
	'turų',
	'turą',
	'turu',
	'turo',
	'ture',
	'turi',
	'turį',
	'tūrį',
	'tūri',
	'turs',
	'turš',
	'tuso',
	'tusę',
	'tusi',
	'tušų',
	'tušą',
	'tušu',
	'tušo',
	'tuše',
	'tūta',
	'tūtų',
	'tūtą',
	'tūzų',
	'tūzą',
	'tūzu',
	'tūzo',
	'tūze',
	'tūžį',
	'tūži',
	'tūžo',
	'tūžę',
	'tūžk',
	'tūši',
	'tūžt',
	'tvos',
	'tvok',
	'tvot',
	'ūbas',
	'ūbus',
	'ūbui',
	'ūbai',
	'ūbėt',
	'ūbęs',
	'ūbėm',
	'ūbei',
	'ūbia',
	'ūbią',
	'ūbiu',
	'ūbti',
	'ūbtų',
	'ūbtą',
	'ūbtu',
	'ūbto',
	'ūbsi',
	'ūbta',
	'ūdys',
	'ūdyk',
	'ūdyt',
	'ūdra',
	'ūdrų',
	'ūdrą',
	'ugdė',
	'ugdę',
	'ugdo',
	'ugdą',
	'ūgės',
	'ūgėk',
	'ūgėt',
	'ūgiu',
	'ūgit',
	'ūgįs',
	'ūgim',
	'ūgis',
	'ūgių',
	'ūgio',
	'ūglį',
	'ūgli',
	'ugnį',
	'ugny',
	'ugrų',
	'ugrą',
	'ugru',
	'ugro',
	'ugre',
	'ugrė',
	'ugrę',
	'uiti',
	'uitų',
	'uitą',
	'uitu',
	'uito',
	'uisi',
	'uita',
	'ujat',
	'ująs',
	'ujam',
	'ujau',
	'ujot',
	'ujęs',
	'ujus',
	'ujom',
	'ujai',
	'ūkas',
	'ūkus',
	'ūkui',
	'ūkai',
	'ūkėt',
	'ūkęs',
	'ūkėm',
	'ūkei',
	'ūkia',
	'ūkią',
	'ūkiu',
	'ūkis',
	'ūkių',
	'ūkio',
	'ūksi',
	'ūksį',
	'ūkti',
	'teūk',
	'neūk',
	'beūk',
	'ūktų',
	'ūktą',
	'ūktu',
	'ūkit',
	'ūkto',
	'ūkim',
	'ūkta',
	'ulba',
	'ulbą',
	'ulbu',
	'ulbi',
	'ulda',
	'uldą',
	'uldu',
	'uldi',
	'ulių',
	'ules',
	'ulės',
	'ulėm',
	'ulėj',
	'ulei',
	'ūmas',
	'ūmus',
	'ūmos',
	'ūmom',
	'ūmam',
	'ūmoj',
	'ūmai',
	'ūmės',
	'ūmėk',
	'ūmėt',
	'ūmių',
	'ūmią',
	'ūmiu',
	'ūmūs',
	'ūmia',
	'unkš',
	'uodų',
	'uodą',
	'uodu',
	'uodo',
	'uode',
	'uodė',
	'uodę',
	'uodį',
	'uodi',
	'uoga',
	'uogų',
	'uogą',
	'uogė',
	'uogę',
	'uoge',
	'uola',
	'uolų',
	'uolą',
	'uoli',
	'uolu',
	'uosį',
	'uosi',
	'uosk',
	'uost',
	'uotų',
	'uotą',
	'uotu',
	'uoto',
	'uote',
	'ūpas',
	'ūpus',
	'ūpui',
	'ūpai',
	'upių',
	'upes',
	'upės',
	'upėm',
	'upėj',
	'upei',
	'urbė',
	'urbę',
	'urbi',
	'urbs',
	'urbk',
	'urbt',
	'urgs',
	'urmų',
	'urmą',
	'urmu',
	'urmo',
	'urme',
	'urna',
	'urnų',
	'urną',
	'urpė',
	'urpę',
	'urpi',
	'urps',
	'urpk',
	'urpt',
	'uršk',
	'urši',
	'uršt',
	'urvų',
	'urvą',
	'urvu',
	'urvo',
	'urve',
	'usnį',
	'usny',
	'utos',
	'utas',
	'utom',
	'utoj',
	'utai',
	'užak',
	'ūžėt',
	'ūžęs',
	'ūžus',
	'ūžėm',
	'ūžei',
	'užės',
	'ūžia',
	'ūžią',
	'ūžiu',
	'ūžis',
	'ūžių',
	'ūžio',
	'ūžlė',
	'ūžlę',
	'ūžle',
	'ūžmų',
	'ūžmą',
	'ūžmu',
	'ūžmo',
	'ūžme',
	'ūžti',
	'teūš',
	'neūš',
	'beūš',
	'ūžtų',
	'ūšią',
	'ūžtą',
	'ūšiu',
	'ūžtu',
	'ūšit',
	'ūšis',
	'ūžto',
	'ūšim',
	'ūžta',
	'užūš',
	'vada',
	'vadų',
	'vadą',
	'vadu',
	'vado',
	'vade',
	'vadė',
	'vadę',
	'vadi',
	'vaga',
	'vagų',
	'vagą',
	'vagi',
	'vagį',
	'vagy',
	'vaje',
	'vajė',
	'vajų',
	'vają',
	'vaji',
	'vaja',
	'vaju',
	'valų',
	'valą',
	'valu',
	'valo',
	'vale',
	'valė',
	'valę',
	'vali',
	'vapa',
	'vapų',
	'vapą',
	'vapu',
	'vapi',
	'vapt',
	'varų',
	'varą',
	'varu',
	'varo',
	'vare',
	'varė',
	'varę',
	'vark',
	'varį',
	'vari',
	'vary',
	'vąšų',
	'vąšą',
	'vąšu',
	'vąšo',
	'vąše',
	'vašų',
	'vaši',
	'vašu',
	'vata',
	'vatų',
	'vatą',
	'vatu',
	'vato',
	'vate',
	'vaza',
	'vazų',
	'vazą',
	'važį',
	'važi',
	'važy',
	'važų',
	'važu',
	'večų',
	'večą',
	'veču',
	'večo',
	'veči',
	'veča',
	'veda',
	'vedą',
	'vedu',
	'vedi',
	'vedė',
	'vedę',
	'veik',
	'veis',
	'veja',
	'vejų',
	'veją',
	'veju',
	'veji',
	'vėjų',
	'vėją',
	'vėju',
	'vėjo',
	'vėlė',
	'vėlę',
	'vėle',
	'velė',
	'velę',
	'veli',
	'vels',
	'velk',
	'velt',
	'vėlų',
	'vėli',
	'vėlu',
	'vėmė',
	'vėmę',
	'vemi',
	'vėmį',
	'vėmi',
	'vems',
	'vemk',
	'vemt',
	'vena',
	'venų',
	'veną',
	'venk',
	'vepa',
	'vepą',
	'vepu',
	'vepi',
	'vėpė',
	'vėpę',
	'vepę',
	'vėpo',
	'vept',
	'vėpt',
	'veps',
	'vepk',
	'vėps',
	'vėpk',
	'vėrė',
	'vėrę',
	'vėre',
	'verk',
	'veri',
	'vers',
	'vert',
	'verš',
	'vėsa',
	'vėsų',
	'vėsą',
	'vėsį',
	'vėsi',
	'vėso',
	'vėsę',
	'vėsk',
	'vėst',
	'vesk',
	'vesi',
	'vest',
	'vėsu',
	'veša',
	'vešą',
	'vešu',
	'veši',
	'vešų',
	'vėtė',
	'vėtę',
	'veto',
	'vėto',
	'vėtą',
	'veža',
	'vežą',
	'vežu',
	'veži',
	'vėžė',
	'vėžę',
	'vėže',
	'vežė',
	'vežę',
	'vėžį',
	'vėžy',
	'vežk',
	'vežt',
	'vidų',
	'vien',
	'vija',
	'vijų',
	'viją',
	'vijo',
	'viję',
	'vikį',
	'viki',
	'vila',
	'vilų',
	'vilą',
	'vilk',
	'vili',
	'vils',
	'vilt',
	'vinį',
	'viny',
	'vipo',
	'vipę',
	'vipt',
	'vips',
	'vipk',
	'virė',
	'virę',
	'virį',
	'viri',
	'virs',
	'virš',
	'virk',
	'virt',
	'visa',
	'viso',
	'visę',
	'visk',
	'visi',
	'vist',
	'viza',
	'vizų',
	'vizą',
	'vyko',
	'vykę',
	'vyks',
	'vykt',
	'vylė',
	'vylę',
	'vylų',
	'vyli',
	'vylu',
	'vyma',
	'vymų',
	'vymą',
	'vynų',
	'vyną',
	'vynu',
	'vyno',
	'vyne',
	'vypo',
	'vypę',
	'vypt',
	'vyps',
	'vypk',
	'vyrų',
	'vyrą',
	'vyru',
	'vyro',
	'vyre',
	'vyrį',
	'vyri',
	'vysk',
	'vysi',
	'vyst',
	'vyti',
	'vytų',
	'vytą',
	'vytu',
	'vyto',
	'vyta',
	'vytį',
	'vyty',
	'vytę',
	'vyža',
	'vyžų',
	'vyžą',
	'vyžė',
	'vyžę',
	'vyže',
	'vyžk',
	'vyši',
	'vyžt',
	'vogė',
	'vogę',
	'vogį',
	'vogi',
	'vogs',
	'vogt',
	'vokų',
	'voką',
	'voku',
	'voko',
	'voke',
	'vokė',
	'vokę',
	'voki',
	'voks',
	'vokt',
	'volų',
	'volą',
	'volu',
	'volo',
	'vole',
	'volė',
	'volę',
	'vora',
	'vorų',
	'vorą',
	'vorė',
	'voru',
	'voro',
	'vore',
	'votį',
	'voty',
	'vožė',
	'vožę',
	'voži',
	'vožį',
	'vožk',
	'voši',
	'vožt',
	'zauk',
	'zenk',
	'zirk',
	'zirs',
	'zykė',
	'zykę',
	'zyki',
	'zyks',
	'zykt',
	'zylė',
	'zylę',
	'zyle',
	'zyzė',
	'zyzę',
	'zyzi',
	'zyzk',
	'zysi',
	'zyzt',
	'zmek',
	'zona',
	'zonų',
	'zoną',
	'zuis',
	'zuik',
	'zuit',
	'zuja',
	'zują',
	'zuju',
	'zuji',
	'zujo',
	'zuję',
	'zurk',
	'zurs',
	'zūzė',
	'zūzę',
	'zūzi',
	'zūzk',
	'zūsi',
	'zūzt',
	'žabų',
	'žabą',
	'žabu',
	'žabo',
	'žabe',
	'žada',
	'žadą',
	'žadu',
	'žadi',
	'žadų',
	'žado',
	'žade',
	'žagų',
	'žagą',
	'žagu',
	'žago',
	'žage',
	'žagė',
	'žagę',
	'žagi',
	'žags',
	'žagt',
	'žais',
	'žala',
	'žalų',
	'žalą',
	'žąla',
	'žąlą',
	'žąlu',
	'žąli',
	'žalu',
	'žalo',
	'žali',
	'žalė',
	'žalę',
	'žale',
	'žalį',
	'žals',
	'žalk',
	'žalt',
	'žara',
	'žarų',
	'žarą',
	'žąsų',
	'žąsį',
	'žąsy',
	'žava',
	'žavų',
	'žavą',
	'žavi',
	'žavį',
	'žavu',
	'žėbė',
	'žėbę',
	'žėbi',
	'žėbs',
	'žėbk',
	'žėbt',
	'žeis',
	'želė',
	'želę',
	'žele',
	'žėlė',
	'žėlę',
	'želi',
	'žels',
	'želk',
	'želt',
	'žemų',
	'žemą',
	'žemu',
	'žemo',
	'žemi',
	'žema',
	'žemė',
	'žemę',
	'žeme',
	'žemį',
	'žemy',
	'ženk',
	'žėrė',
	'žėrę',
	'žerk',
	'žėri',
	'žėrį',
	'žeri',
	'žers',
	'žert',
	'žiba',
	'žibą',
	'žibu',
	'žibi',
	'žibė',
	'žibę',
	'žibe',
	'žibt',
	'žibų',
	'žies',
	'žigų',
	'žigą',
	'žigu',
	'žigo',
	'žige',
	'žilų',
	'žilą',
	'žilu',
	'žilo',
	'žili',
	'žila',
	'žilė',
	'žilę',
	'žile',
	'žilį',
	'žils',
	'žilk',
	'žilt',
	'žino',
	'žiną',
	'žios',
	'žiok',
	'žiot',
	'žirų',
	'žirą',
	'žiru',
	'žiro',
	'žire',
	'žirę',
	'žirs',
	'žirk',
	'žirt',
	'žįsk',
	'žįsi',
	'žįst',
	'žižė',
	'žižę',
	'žiže',
	'žybt',
	'žydų',
	'žydą',
	'žydu',
	'žydo',
	'žyde',
	'žydė',
	'žydę',
	'žydi',
	'žydį',
	'žygį',
	'žygi',
	'žyla',
	'žylą',
	'žylu',
	'žyli',
	'žylį',
	'žymė',
	'žymę',
	'žyme',
	'žymi',
	'žymį',
	'žymų',
	'žymu',
	'žynė',
	'žynę',
	'žyne',
	'žynį',
	'žyny',
	'žyra',
	'žyrą',
	'žyru',
	'žyri',
	'žysk',
	'žysi',
	'žyst',
	'žlėk',
	'žluk',
	'žnek',
	'žodį',
	'žodi',
	'žolė',
	'žolę',
	'žole',
	'žudė',
	'žudę',
	'žudo',
	'žudą',
	'žūti',
	'žūtų',
	'žūtą',
	'žūtu',
	'žūto',
	'žūsi',
	'žūta',
	'žūtį',
	'žūty',
	'žūva',
	'žūvą',
	'žūvu',
	'žūvi',
	'žuvų',
	'žuvį',
	'žuvy',
	'žuvo',
	'žuvę',
]