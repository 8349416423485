import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import {WordPair} from "../../lib/words";
import React from "react";
import {StartRow} from "./StartRow";
import {TargetRow} from "./TargetRow";

type Props = {
  wordPair: WordPair
  guesses: string[]
  currentGuess: string
  isGameWon: boolean
  currentRowClassName: string
  handleReset: () => void
}

export const Grid = ({
  wordPair,
  guesses,
  currentGuess,
  isGameWon,
  currentRowClassName,
  handleReset
}: Props) => {

  return (
    <>
      <StartRow word={wordPair.start}/>
      {guesses.map((guess, i) => !(isGameWon && guess === wordPair.target) && (
        <CompletedRow
          key={i}
          target={wordPair.target}
          guess={guess}
        />
      ))}
      {!isGameWon && (
        <CurrentRow guess={currentGuess} className={currentRowClassName} />
      )}
      <TargetRow wordPair={wordPair} guesses={guesses}/>
      {guesses.length > 0 && (
        <div className="mt-2 mb-1 flex justify-center">
          <button className={"dark:text-white cursor-pointer underline hover:no-underline"} onClick={handleReset}>Žaisti iš naujo</button>
        </div>
      )}
    </>
  )
}
