import { unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  target: string
  guess: string
}

export const CompletedRow = ({ target, guess }: Props) => {
  const splitTarget = unicodeSplit(target)
  const splitGuess = unicodeSplit(guess)

  return (
    <div className="mb-1 flex justify-center">
      {splitGuess.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          status={splitGuess[i] === splitTarget[i] ? 'correct' : undefined}
          position={i}
          isCompleted
        />
      ))}
    </div>
  )
}
